export const environment: Gnosis.Environment = {
  production: false,
  auth: {
    firebaseProvider: {
      identityProvider: 'FIREBASE',
      authProvider: 'oidc.azure',
      firebase: {
        apiKey: 'AIzaSyB97KbJQbHTlUOqdPnN2Y6WLmqiSYt81jU',
        authDomain: 'deloitte-test.apigee.net',
        projectId: 'dcsptest',
      },
      redirectUri: '/scenario',
      graphqlUrl: 'https://gnosis-test.api.deloitte.ai',
    }
  },
  features: { alliances_tracker: false, dynamic_strategy: true },
};
