import { EMPTY as observableEmpty, Observable, of as observableOf, ReplaySubject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { ApiService } from '../api.service';
import { EditionService } from './edition.service';

export const createMessageUnderEntityMutation = `
  mutation createMessageUnderEntity($message:String!, $entityType:String!, $entityId:Int!, $editionId:Int!) {
    createMessageUnderEntity(
      content: $message,
      entity_type: $entityType,
      entity_id: $entityId,
      edition_id: $editionId
    ) {
      id
      entityId: entity_id
      entityType: entity_type
    }
  }
`;

export enum ScenarioChartEntityIds {
  CircleChart = 1,
  ProbabilityChart = 2,
}

// for reused type inside of this file
interface MessageChangeItem {
  type: string;
  entityType: MessagesModule.EntityType;
  entityId: number;
}
@Injectable()
export class CommunicationService {
  userId;
  private $messagesChangeSubject = new ReplaySubject<MessageChangeItem>(1);
  $messagesChange: Observable<MessageChangeItem>;

  constructor(private apiService: ApiService, private editionService: EditionService) {
    this.$messagesChange = this.$messagesChangeSubject.asObservable();
  }

  public listForEntityCount(entityType: MessagesModule.EntityType, entityIdArray: number[]):
  Observable<Gnosis.MessageForCountNumber> {
    // here we generate the query for all items in one page
    const generatedQuery = this.generateMessagesEntityQuery(entityType, entityIdArray);
    if (generatedQuery) {
      return this.apiService.apollo.query({
        query: gql(`
            ${generatedQuery}
            `)
      }).pipe(
        map((result) => {

          // NOTE: message is broken here, because this branch does not have the newest change of feature/messaging
          const messageCounts = {};
          if (result && result.data) {
            Object.keys(result.data).forEach(key => {
              messageCounts[key] = (result.data[key] || []).length;
            });
          }

          return messageCounts;
        }));
    }

    return observableEmpty;
  }

  private generateMessagesEntityQuery(entityType: MessagesModule.EntityType, entityIdArray: number[]): string | null {
    // customize the query, what we finally get is the query of messages of items on each page
    // the basic structure is for example like this:
    // {
    //  files1: messagesEntity(edition_id: 2, entity_type: "files", entity_id: 6)
    //   {
    //    id
    //   }
    //  ...
    //  filesn: messagesEntity(...) {...}
    // }

    // we can later add limit and offset parameters here is there are too many messages for one item
    if (entityIdArray.length > 0) {
      let entities = '';
      entityIdArray.forEach(entityId => {
        entities += `${entityType}${entityId}:
        messagesEntity(edition_id: ${this.editionService.currentEditionId}, entity_type: "${entityType}", entity_id: ${entityId}){
          id
        }
        `;
      });
      return `{
        ${entities}
      }`;
    }
    return null;
  }

  public create(newMessage: MessagesModule.NewMessage) {

    return this.apiService.apollo.mutate({
      mutation: gql(createMessageUnderEntityMutation),
      variables: {
        ...newMessage,
        editionId: this.editionService.currentEditionId
      }
    }).pipe(mergeMap(response => {
      // notify UI to update its message count after server added the new messages into its database

      //const item = response.data.createMessageUnderEntity; // c by PRAT
      const item = (response as any).data.createMessageUnderEntity; // a by PRAT

      this.$messagesChangeSubject.next({
        type: 'addMessage',
        entityType: item.entityType,
        entityId: item.entityId
      });

      return observableOf(item);
    }));
  }

  // utilize functions, which have nothing to do with data, but are used multiple times in the app
  public getMessageCountLabel(messages: Gnosis.MessageForCountNumber, messageType: MessagesModule.EntityType, id: number) {
    const key = `${messageType}${id}`;
    if (messages && messages[key]) {
      const count = (messages[key]);
      return count > 0 ? count : '+';
    }
    return '+';
  }

  public updateMessageCount(messages: Gnosis.MessageForCountNumber, messageChange: MessageChangeItem) {
    if (!messageChange || !messages) {
      return messages;
    }

    const key = `${messageChange.entityType}${messageChange.entityId}`;
    if (messages[key]) {
      messages[key] = messages[key] + 1;
    }
    return messages;
  }
}
