import { from as observableFrom, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { UsersService } from '../core/users.service';

@Injectable()
export class AuthAdminGuard implements CanLoad {
  get canManageUsers$() {
    return observableFrom(
      this.permissionsService.hasPermission('manage_users')
    );
  }

  constructor(
    private userService: UsersService,
    private router: Router,
    private permissionsService: NgxPermissionsService
  ) {}

  canActivate(): Observable<boolean> {
    return this.userService.user.pipe(
      switchMap(() => this.canManageUsers$),
      map((canManageUsers) => {
        if (canManageUsers) {
          return true;
        }
        this.router.navigate(['overview']);
        return false;
      })
    );
  }

  canLoad(): Observable<boolean> {
    return this.userService.user.pipe(
      switchMap(() => this.canManageUsers$),
      map((canManageUsers) => {
        if (canManageUsers) {
          return true;
        }
        this.router.navigate(['overview']);
        return false;
      }),
      take(1)
    );
  }
}
