import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { take, switchMap, map } from 'rxjs/operators';
import { ApiService } from '../api.service';
import gql from 'graphql-tag';
import { ObjectNumberKeys } from '../shared/utils';

const filterSetsListQuery = `
  query filterSetsList($name: String){
    indicatorNewsFilters(name_prefix: $name){
      id: name
      name
      filter
    }
  }
`;

const createFilterSetMutation = `
  mutation createFiltersSet($name: String!, $filter: String!){
    createIndicatorNewsFilters(name: $name, filter: $filter){
      id: name
      name
      filter
    }
  }
`;

const updateFilterSetMutation = `
  mutation updateFilterSet($name: String!, $filter: String!) {
    updateIndicatorNewsFilters(name: $name, filter: $filter) {
      id: name
      name
      filter
    }
  }
`;

@Injectable()
export class FilteringService {
  filters: Observable<ScenarioModule.AllFilters>;

  private frontendTimeframe: NewsTable.Date;

  private indicatorId2AxisId = {} as { [id: number]: number };
  private defaultIndicatorId2AxisId = {} as { [id: number]: number };
  private static get emptyFilterSet(): ScenarioModule.FilterArguments {
    return {
      text: '',
      title: '',
      selectedCountries: [],
      selectedRegions: [],
      selectedSources: [],
      sourceRank: [1, 5],
      publishDate: [null, null]
    };
  }

  // Used to cache the dirty/clean state and influence the currentFilters properly
  // For currentFilters, if there are some filters in indicator-x
  // it means, the filters of indicator-x are different from its parents(axis) filters, and the data will be refetched
  private defaultIndicatorsFilterState: ScenarioModule.IndicatorFilterState = {};

  // Summary: the currentFilters is used to keep the track of every filter change
  // 1. The chart data fully depends on changes from currentFilters
  // 2. while the indicator list component manage their own filters state
  // (in the component<CollapsibleIndicatorList> & <ScenarioStat> & <ScenarioNewsTable>)
  // currentFilters can overwrite the indicator list's filter state
  // only when the user require it explicitly (click the reset to default button)
  private currentFilters: ScenarioModule.AllFilters = {
    axes: {},
    indicators: {},
    indicatorFilterState: {},
    shouldUpdateIndicatorListData: false
  };

  private filtersSubject = new ReplaySubject<ScenarioModule.AllFilters>(1);
  private defaultFilterSet: ScenarioModule.AllFilters = {};
  private defaultFilterSetName = 'initial';

  constructor(private apiService: ApiService) {
    this.filters = this.filtersSubject.asObservable();
  }

  fetchInitialSet() {
    this.getFilteringSet(this.defaultFilterSetName)
      .pipe(take(1))
      .subscribe((filters) => {
        this.defaultFilterSet = this.getDeepCopyOfFiltersArguments({
          axes: filters.axes,
          indicators: filters.indicators
        });
        this.defaultIndicatorsFilterState = this.getDeepCopyOfFiltersState(
          filters.indicatorFilterState
        );
        this.defaultIndicatorId2AxisId = { ...filters.indicatorId2AxisId };
        this.setAllFilters(
          this.defaultFilterSet.axes,
          this.defaultFilterSet.indicators,
          this.defaultIndicatorId2AxisId,
          this.defaultIndicatorsFilterState
        );
        this.reload();
      });
  }

  setAxisFilters(
    filters: Partial<ScenarioModule.FilterArguments>,
    axisId: number
  ): void {
    // set axis filters state
    this.currentFilters.axes[axisId] = {
      ...FilteringService.emptyFilterSet,
      ...(this.currentFilters.axes[axisId]
        ? this.currentFilters.axes[axisId]
        : {}),
      ...filters
    };

    // update indicators' filters state
    const relevantIndicatorIds = ObjectNumberKeys(
      this.indicatorId2AxisId
    ).filter((id) => this.indicatorId2AxisId[id] === axisId);
    ObjectNumberKeys(this.currentFilters.indicatorFilterState).forEach((id) => {
      if (relevantIndicatorIds.find((i) => i === id)) {
        Object.keys(filters).forEach((filterKey) => {
          this.currentFilters.indicatorFilterState[id][filterKey] = 'clean';
        });
      }
    });

    const dirtyIndicatorsIds = ObjectNumberKeys(
      this.currentFilters.indicatorFilterState
    ).filter((id) => {
      // tslint:disable-next-line:max-line-length
      const allFiltersClean = Object.keys(
        this.currentFilters.indicatorFilterState[id]
      ).every(
        (filterKey) =>
          this.currentFilters.indicatorFilterState[id][filterKey] === 'clean'
      );
      return !allFiltersClean;
    });

    const filterResult = {} as any;
    const filterStateResult: ScenarioModule.IndicatorFilterState = {};
    dirtyIndicatorsIds.forEach((dirtyId) => {
      filterResult[dirtyId] = {
        ...FilteringService.emptyFilterSet,
        ...this.currentFilters.indicators[dirtyId],
        ...filters
      };
      filterStateResult[dirtyId] = this.currentFilters.indicatorFilterState[
        dirtyId
      ];
    });
    // set currentFilters on indicators only when they are dirty
    this.currentFilters.indicators = filterResult;
    this.currentFilters.indicatorFilterState = filterStateResult;

    this.reload();
  }

  setIndicatorFilters(
    filters: Partial<ScenarioModule.FilterArguments>,
    indicatorId: number,
    axisId: number
  ): any {
    this.indicatorId2AxisId[indicatorId] = axisId;

    // mark relevant filter state of this indicator as dirty
    if (!this.currentFilters.indicatorFilterState[indicatorId]) {
      const result = {} as {
        [key in keyof ScenarioModule.FilterArguments]: 'dirty' | 'clean';
      };
      Object.keys(FilteringService.emptyFilterSet).forEach((filterKey) => {
        result[filterKey] = 'clean';
      });
      this.currentFilters.indicatorFilterState[indicatorId] = result;
    }
    Object.keys(filters).forEach((filterKey) => {
      this.currentFilters.indicatorFilterState[indicatorId][filterKey] =
        'dirty';
    });

    // set current filters
    this.currentFilters.indicators[indicatorId] = {
      ...this.currentFilters.axes[axisId],
      ...(this.currentFilters.indicators[indicatorId]
        ? this.currentFilters.indicators[indicatorId]
        : {}),
      ...filters
    };

    this.reload();
  }

  resetAll() {
    this.setAllFilters();
    this.reload(false, true);
  }

  setToDefault() {
    this.setAllFilters(
      this.defaultFilterSet.axes,
      this.defaultFilterSet.indicators,
      this.defaultIndicatorId2AxisId,
      this.defaultIndicatorsFilterState
    );
    this.reload(false, true);
  }

  reload(forceRequest = false, shouldUpdateIndicatorListData = false) {
    // Without forceRequest request might not be made because of caching
    if (forceRequest) {
      this.currentFilters.timestamp = new Date().getTime();
    }
    this.currentFilters.shouldUpdateIndicatorListData = shouldUpdateIndicatorListData;
    this.filtersSubject.next({ ...this.currentFilters });
  }

  saveDefaultFilterSet() {
    this.saveFilteringSet();
    // update locally
    this.defaultFilterSet = this.getDeepCopyOfFiltersArguments(
      this.currentFilters
    );
    this.defaultIndicatorsFilterState = this.getDeepCopyOfFiltersState(
      this.currentFilters.indicatorFilterState
    );
    this.defaultIndicatorId2AxisId = this.indicatorId2AxisId;
  }

  saveFilteringSet(name = this.defaultFilterSetName) {
    const filtersString: ScenarioModule.AllFilters = {
      ...this.currentFilters,
      indicatorId2AxisId: this.indicatorId2AxisId
    };

    // @Mark, apollo cache can not be updated correctly here, so we temporarily decide to use no-cache strategy here so that
    // we can always get the latest update from backend.
    // @TODO, to debug why apollo cache can not be updated correctly if we have time
    const storeFilteringSetSubscription = this.apiService.apollo
      .use('no-cache')
      .query({
        query: gql(`${filterSetsListQuery}`),
        variables: {
          name
        }
      })
      .pipe(
        switchMap((result) => {
          // if not exists
          if ((result.data as any).indicatorNewsFilters.length === 0) {
            return this.apiService.apollo.mutate({
              mutation: gql(`${createFilterSetMutation}`),
              variables: {
                name,
                filter: JSON.stringify(filtersString)
              }
            });
          }

          // if exists
          return this.apiService.apollo.mutate({
            mutation: gql(`${updateFilterSetMutation}`),
            variables: {
              name,
              filter: JSON.stringify(filtersString)
            }
          });
        })
      )
      .subscribe(() => {
        storeFilteringSetSubscription.unsubscribe();
      });
  }

  private getFilteringSet(name: string): Observable<ScenarioModule.AllFilters> {
    return this.apiService.apollo
      .query({
        query: gql(`${filterSetsListQuery}`),
        variables: {
          name
        }
      })
      .pipe(
        map((result) => {
          // if not exists
          if ((result.data as any).indicatorNewsFilters.length === 0) {
            return {
              axes: {
                1: FilteringService.emptyFilterSet,
                2: FilteringService.emptyFilterSet
              },
              indicators: {},
              indicatorId2AxisId: {},
              indicatorFilterState: {}
            };
          }
          // if exists
          const filters: ScenarioModule.AllFilters = JSON.parse(
            (result.data as any).indicatorNewsFilters[0].filter
          );
          return {
            axes: filters.axes,
            indicators: filters.indicators,
            indicatorId2AxisId: filters.indicatorId2AxisId,
            indicatorFilterState: filters.indicatorFilterState
          };
        })
      );
  }

  private setAllFilters(
    axes: ScenarioModule.AxisFilters = {
      1: FilteringService.emptyFilterSet,
      2: FilteringService.emptyFilterSet
    },
    indicators: ScenarioModule.IndicatorFilters = {},
    indicatorId2AxisId = {} as { [id: number]: number },
    indicatorFilterState: ScenarioModule.IndicatorFilterState = {}
  ) {
    // tslint:disable-next-line:max-line-length
    this.currentFilters = {
      ...this.getDeepCopyOfFiltersArguments({ axes, indicators }),
      indicatorFilterState: this.getDeepCopyOfFiltersState(
        indicatorFilterState
      )
    };
    this.indicatorId2AxisId = { ...indicatorId2AxisId };
  }

  setFrontendFilter(timeframe: NewsTable.Date): void {
    this.frontendTimeframe = timeframe;
  }

  getAppliedFiltersCount(filters: ScenarioModule.FilterArguments): number {
    let appliedFilterCount = 0;
    if (filters.text !== '') {
      appliedFilterCount += 1;
    }
    if (filters.title !== '') {
      appliedFilterCount += 1;
    }
    if (filters.selectedCountries.length !== 0) {
      appliedFilterCount += 1;
    }
    if (filters.selectedSources.length !== 0) {
      appliedFilterCount += 1;
    }
    if (filters.publishDate[0] !== null) {
      appliedFilterCount += 1;
    }
    if (filters.sourceRank[0] !== 1 || filters.sourceRank[1] !== 5) {
      appliedFilterCount += 1;
    }
    return appliedFilterCount;
  }

  // helper functions for displaying the active filtering message
  get hasAnyActiveFilter() {
    return (
      this.frontendTimeframe ||
      ObjectNumberKeys(this.currentFilters.axes).some((id) =>
        this.hasAxesActiveFilter(id)
      ) ||
      ObjectNumberKeys(this.currentFilters.indicators).some((id) =>
        this.hasIndicatorActiveFilter(id)
      )
    );
  }

  hasAxesActiveFilter(axesId: number) {
    return this.getAppliedFiltersCount(this.currentFilters.axes[axesId]) > 0;
  }

  hasIndicatorActiveFilter(indicatorId: number) {
    return (
      this.currentFilters.indicators[indicatorId] &&
      this.getAppliedFiltersCount(this.currentFilters.indicators[indicatorId]) >
        0
    );
  }

  private getDeepCopyOfFiltersArguments(
    filters: ScenarioModule.AllFilters
  ): ScenarioModule.AllFilters {
    const axes = {} as ScenarioModule.AxisFilters;
    const indicators = {} as ScenarioModule.IndicatorFilters;
    ObjectNumberKeys(filters.axes).forEach((axisId) => {
      axes[axisId] = { ...filters.axes[axisId] };
    });
    ObjectNumberKeys(filters.indicators).forEach((indicatorId) => {
      indicators[indicatorId] = { ...filters.indicators[indicatorId] };
    });

    return {
      axes,
      indicators
    };
  }

  private getDeepCopyOfFiltersState(
    filtersStates: ScenarioModule.IndicatorFilterState
  ): ScenarioModule.IndicatorFilterState {
    const state = {} as ScenarioModule.IndicatorFilterState;
    ObjectNumberKeys(filtersStates).forEach((id) => {
      state[id] = { ...filtersStates[id] };
    });
    return state;
  }
}
