import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';

export interface ChatParams {
  visibility: boolean;
  entityType?: MessagesModule.EntityType;
  entityId?: any;
}

@Injectable()
export class ModalService {
  chat$: Observable<ChatParams>;
  chatSubject: BehaviorSubject<ChatParams>;

  isGlobalLoading$: Observable<boolean>;
  private isGlobalLoadingSubject: BehaviorSubject<boolean>;

  constructor() {
    this.chatSubject = new BehaviorSubject({ visibility: false });
    this.chat$ = this.chatSubject.asObservable();

    this.isGlobalLoadingSubject = new BehaviorSubject(true);
    this.isGlobalLoading$ = this.isGlobalLoadingSubject.asObservable();
  }

  public setChatVisibility(chatParams: ChatParams) {
    if (window.getSelection && window.getSelection().toString().length <= 0 || !chatParams.visibility) {
      this.chatSubject.next(chatParams);
    }
  }

  public setGlobalLoadingState(state: boolean) {
    this.isGlobalLoadingSubject.next(state);
  }
}
