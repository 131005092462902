<div *ngIf="isLoading" class="loading-wrapper">
  <div class="loading-image">
    <img src="/assets/loading-dots.gif" width="600" height="600" alt="loading">
  </div>
  <h3 class="reload-message" *ngIf="showReloadMessage">Problem loading page? Try <a href="/">reload</a> it!</h3>
</div>
<header class="header" [class.header--under-scenario-module]="isScenarioStatsModule"
        [class.header--collapsed]="isHeaderCollapsed && isScenarioStatsModule">
  <div class="header__wrapper">
    <div class="header__title">
      <h1 class="header__item-h1" routerLink="/scenario">Gnosis.<span class="header__item-highlight">strategy</span></h1>
    </div>

    <div class="header__navigation__container">
      <a class="header__navigation__item" routerLink="/scenario" routerLinkActive="header__navigation__item--active">
        Dynamic Scenarios
      </a>
      <a *ngIf="showIndicatorModule" class="header__navigation__item"
         routerLink="/indicators" routerLinkActive="header__navigation__item--active">
        Indicator Description
      </a>
      <a *ngIf="showAllianceTrackerModule" class="header__navigation__item" routerLink="/alliance-tracker"
         routerLinkActive="header__navigation__item--active">
        Athena
      </a>
    </div>

    <div class="header__item">
      <div class="menu">
        <ng-template [ngIf]="currentUser">
          <div class="menu-button">
            <div class="menu__profile-picture icon-profile-default"></div>
            <span class="menu__button-text profile__text">{{currentUser?.username}}</span>

          </div>
          <div class="menu-button menu-button__loggout-group" (click)="logout()">
            <div class="logout__logo"></div>
            <p class="menu__button-text logout__text">Logout</p>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="header__logo">
      <img src="/assets/deloitte-logo.svg" alt="Deloitte" />
    </div>
  </div>

  <!-- extra header only exist under scenario module -->
  <ng-container *ngIf="isScenarioStatsModule && !isHeaderCollapsed">
    <div class="scenario-module-header">
      <div class="scenario-module-header__welcome-info">
        <span  class="scenario-module-header__welcome-info-top">Welcome <span class="welcome-info-name">{{currentUser.username}},</span></span>
        <div>there have been changes <span>{{scenariosRealizationRateComparedRangeLabel}}</span>:</div>
      </div>

      <ng-container [ngSwitch]="isScenarioChangesDataLoading">
        <div *ngSwitchCase="false" class="scenario-module-header__scenario-changes">
          <!-- <div class="scenario-changes">
            <div class="scenario-changes__number">{{newScenarioEventsItems?.indicatorCount}}</div>
            <div class="scenario-changes__title">Indicators</div>
          </div> -->

          <div class="scenario-changes">
            <div class="scenario-changes__number">{{newScenarioEventsItems?.eventCount | number : '1.0-0' }}</div>
            <div class="scenario-changes__title">New Events</div>
          </div>

          <div *ngFor="let scenario of scenariosRealizationRate" class="scenario-changes">
            <div class="scenario-changes__number">
              <span>{{scenario.startProbability - scenario.endProbability < 0 ? '+' : '–'}}</span>
              {{ getAbsoluteDifference(scenario.startProbability, scenario.endProbability) | number: numberFormat}} pp
            </div>

            <div class="scenario-changes__title">{{scenario.scenarioLabel}}</div>
          </div>
        </div>

        <div *ngSwitchCase="true">
          Calculating changes &hellip;
        </div>
      </ng-container>

    </div>
  </ng-container>
</header>

<main #mainContent
      class="main-content"
      [class.main-content--under-scenario-module]="isScenarioStatsModule"
      [class.main-content--expanded]="isHeaderCollapsed && isScenarioStatsModule">
  <div class="main-content__wrapper">
    <router-outlet></router-outlet>
  </div>
</main>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<!--<p-growl  [life]="3000" [(value)]="toastMsg" [style]="{ backgroundColor: 'white', top: '100px'}"></p-growl>-->
<!--<p-toast  [life]="3000" [(data)]="toastMsg" [style]="{ backgroundColor: 'white', top: '100px'}"></p-toast>-->
