<section class="live-monitoring module-row__item" *ngIf="isLoaded">
  <app-messages-information class="live-monitoring__headline-message-info" [messagesCount]="getMessageCount(globeEntityId, globeMessageType)" [entityId]="globeEntityId" [messageEntityType]="globeMessageType"></app-messages-information>
  <h2 class="live-monitoring__headline"><strong class="live-monitoring__headline-prefix">Live Monitoring</strong> {{ now | async | date: 'yyyy-MM-dd HH:mm:ss' }}</h2>
  <!-- comment out the following paragraph for presentation purpose -->
  <!-- <p class="live-monitoring__subtitle">Total relevant news aspects in the last 24 hours: {{ recentAspects?.length }} </p> -->
</section>

<div class="sidebar" *ngIf="isLoaded">
  <section class="future-development">
    <app-messages-information class="future-development__headline-message-info" [messagesCount]="getMessageCount(entityIds.CircleChart, developmentMessageType)" [entityId]="entityIds.CircleChart" [messageEntityType]="developmentMessageType"></app-messages-information>
    <h2 class="future-development__headline"><strong class="future-development__headline-prefix">Future Development</strong> 2030</h2>
    <ol class="future-development__scenario-list">
      <li class="future-development__scenario-item future-development__scenario-item--scenario-{{i}}" *ngFor="let scenario of data; let i = index">
        <p class="future-development__scenario-probability future-development__scenario-probability--scenario-{{i}}">
          {{scenario.probability | number: '1.0-0'}}%
          <span class="future-development__scenario-probability--difference" *ngIf="isProbabilityDifferenceSignificantArray[i]">
            ({{scenario.probability - scenario.lastWeekProbability | number: '1.0-0' | sign}}%)
          </span>
        </p>
        <h3 class="future-development__scenario-name">
          <i (click)="openNarrationPanel(op, $event, i)" class="fa fa-info-circle scenario-detail"></i>
          {{scenario.name}}
        </h3>
      </li>
    </ol>
    <div class="future-development__axis-vertical"><caption class="future-development__axis-vertical-caption">Control by Machines</caption></div>
    <div class="future-development__axis-horizontal"><caption class="future-development__axis-horizontal-caption">Prevalence of BC Technology</caption></div>

    <span *ngIf="isProbabilityDifferenceShown" class="scenario-probability--difference__explanation">compared to last week</span>
  </section>

  <section class="strategic-options">
    <app-messages-information class="strategic-options__headline-message-info" [messagesCount]="getMessageCount(strategyEntityId, strategyMessageType)" [entityId]="strategyEntityId" [messageEntityType]="strategyMessageType"></app-messages-information>
    <h2 class="strategic-options__headline"><strong class="strategic-options__headline-prefix">Optimal Strategic Options</strong> Wind Tunnelling</h2>
    <ol class="strategic-options__list">
      <li class="strategic-options__item">
        <strong class="strategic-options__item-number">1</strong>
        <p class="strategic-options__item-description">Invest in AI startups from China within the next six months <a class="strategic-options__item-link">Details</a></p>
        <strong class="strategic-options__item-fit">95% fit</strong>
      </li>
      <li class="strategic-options__item">
        <strong class="strategic-options__item-number">2</strong>
        <p class="strategic-options__item-description">Strengthen strategic partnerships with AI corporations to secure access to high performance AI capabilities <a class="strategic-options__item-link">Details</a></p>
        <strong class="strategic-options__item-fit">85% fit</strong>
      </li>
      <li class="strategic-options__item">
        <strong class="strategic-options__item-number">3</strong>
        <p class="strategic-options__item-description">Focus on the following three BCs to develop use-cases: Ethereum, Bitcoin, Hyperledger Fabric <a class="strategic-options__item-link">Details</a></p>
        <strong class="strategic-options__item-fit">80% fit</strong>
      </li>
    </ol>
  </section>
</div>

<p-overlayPanel #op appendTo="body" [style]="{'max-width': '400px', 'padding': '10px'}">
  {{narration}}
</p-overlayPanel>
