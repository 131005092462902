import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireAuthModule } from '@angular/fire/auth';
import {
  AngularFireModule,
  FirebaseOptionsToken,
  FirebaseOptions
} from '@angular/fire';

import { AuthService, JwtInterceptor, SubscriptionInterceptor } from './utils';
import { AuthProviderToken, AuthRedirectUri, AutoLoginToken } from './data';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

export interface AiHubAuthConfiguration {
  authProvider: string;
  redirectUri: string;
  firebase: FirebaseOptions;
  autoLogin?: boolean;
}

@NgModule({
  imports: [
    CommonModule,
    AngularFireAuthModule,
    AngularFireModule
  ],
  exports: [],
  providers: [
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SubscriptionInterceptor,
      multi: true
    }
  ]
})
export class AiHubAuthModule {
  public static forRoot(
    config: AiHubAuthConfiguration
  ): ModuleWithProviders<AiHubAuthModule> {
    return {
      ngModule: AiHubAuthModule,
      providers: [
        { provide: AuthProviderToken, useValue: config.authProvider },
        { provide: FirebaseOptionsToken, useValue: config.firebase },
        { provide: AuthRedirectUri, useValue: config.redirectUri },
        { provide: AutoLoginToken, useValue: config.autoLogin || false }
      ]
    };
  }
}
