import { Component, OnInit, Injector, HostBinding } from '@angular/core';
import { AuthService } from '../auth';
import { Router } from '@angular/router';
import { AuthRedirectUri, AutoLoginToken } from '../auth/src/data';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-aihub-login',
  templateUrl: './login.html',
  styleUrls: ['./login.scss']
})
export class LoginComponent implements OnInit {
  @HostBinding('class.my-auto') true;
  public userLoggedIn = false;
  public isLoading = false;
  public cognitoUserLoggedIn: Subscription;

  public showLocalLogin: boolean;
  public showFirebaseLogin: boolean;
  public showCognitoLogin: boolean;

  private authService: AuthService;

  authRedirectUri: string;
  autoLogin: boolean;

  constructor(
    private router: Router,
    private injector: Injector
  ) {
    this.showLocalLogin = !!environment.auth.localProvider;
    this.showFirebaseLogin = !!environment.auth.firebaseProvider;
    this.showCognitoLogin = !!environment.auth.cognitoProvider;

    if (environment.auth.firebaseProvider) {
      this.authService = this.injector.get<AuthService>(AuthService); // inject authservice only when firebase authentication is enabled.
      this.authRedirectUri = this.injector.get(AuthRedirectUri);
      this.autoLogin = this.injector.get(AutoLoginToken);
    }
  }

  public ngOnInit() {
    this.isLoading = true;
    /**
     * todo rework with angular 9 and typescript 3.7 to
     * isAutologin = this.router.getCurrentNavigation()?.extras.state.autoLogin;
     */
    // const navigation = this.router.getCurrentNavigation();
    // if ( !navigation || navigation.extras.state.autoLogin !== false) {

    if (environment.auth.firebaseProvider) {
      this.authService.isAuthorized().subscribe((authorized) => {
        this.isLoading = false;
        if (authorized) {
          this.userLoggedIn = true;
          this.redirect();
          return;
        }

        if (this.autoLogin) {
          this.doLogin();
        }
      });
    } else {
      // if there is no firebase provider, then there is no need to check whether the user is authorized via firebase,
      // and the page is allowed to load
      this.isLoading = false;
    }
  }

  public doLogin() {
    const urlParams = new URLSearchParams(window.location.search);
    const subscription_id = urlParams.get('subscription_id');
    if(subscription_id) {
      localStorage.setItem('subscription-id', subscription_id);
    }
    localStorage.setItem('authMethod', 'FIREBASE');
    this.performLogin();
  }

  public doLocalLogin() {
    localStorage.setItem('authMethod', 'LOCAL');
    this.router.navigate(['/scenario']);
  }

  public doLoginWithCognito() {
    localStorage.setItem('authMethod', 'COGNITO');
    this.router.navigate(['/scenario']);
  }

  private performLogin() {
    /** display message we will now login */
    this.authService
      .isAuthorized()
      .subscribe((loggedIn) =>
        !loggedIn ? this.authService.login() : this.redirect()
      );
  }

  private redirect() {
    this.router.navigate([this.authRedirectUri]);
  }
}
