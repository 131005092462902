import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class EditModeService {
  isInEditMode: boolean;

  // !Important:
  // If another service subscribe the editMode, don't forget to unsubscribe it in the ngOnDestroy method of the injected component.
  editMode: Observable<boolean>;
  private editModeSubject: BehaviorSubject<boolean>;

  constructor() {
    this.isInEditMode = true;
    this.editModeSubject = new BehaviorSubject(this.isInEditMode);
    this.setEditMode(this.isInEditMode);
    this.editMode = this.editModeSubject.asObservable();
  }

  public setEditMode(value: boolean) {
    this.isInEditMode = value;
    // NOTE: not used at the moment
    try {
      sessionStorage.setItem('editMode', String(value));
    } catch (e) {
      console.error('Cookies are disabled.');
    }
    this.editModeSubject.next(value);
  }
}
