import { Directive, HostListener, Input, HostBinding } from '@angular/core';
import { SortHeaderHostComponent } from './sort-header.host';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[sortingHeader]',
  exportAs: 'sortingHeader'
})
export class SortHeaderDirective {
  private _sortProperty: NewsTable.SortProperty;
  @Input() set property(pro: NewsTable.SortProperty) {
    this._sortProperty = pro;
  }
  get property(): NewsTable.SortProperty {
    return this._sortProperty;
  }
  @Input() currentSort: NewsTable.Sort;

  constructor(private host: SortHeaderHostComponent) {}

  @HostBinding('class.sorting-column') readonly basicClass = true;
  @HostBinding('class.sorting-column--default') get isNotSorted() {
    return !this.isSorted;
  }

  @HostBinding('class.sorting-column--sorted') get isSorted() {
    return this.currentSort.property === this.property;
  }

  @HostBinding('class.sorting-column--ascending') get isAscending() {
    return this.isSorted && this.currentSort.order === 'ASC';
  }

  @HostBinding('class.sorting-column--descending') get isDescending() {
    return this.isSorted && this.currentSort.order === 'DESC';
  }

  @HostListener('click') handleToggleOrder() {
    const newOrder = this.currentSort.order
      ? this.currentSort.order === 'ASC'
        ? 'DESC'
        : 'ASC'
      : 'ASC';
    const newProperty = this.property;

    // change the current sort state
    this.currentSort.order = newOrder;
    this.currentSort.property = newProperty;
    // inform outside the sort state is changed
    this.host.handlingSortingStateChange(
      newProperty,
      newOrder,
      this.currentSort.id
    );
  }
}
