import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import gql from 'graphql-tag';

const getAllEditionsQuery = `
  query getEditions($division_id: Int!) {
    editions(division_id:$division_id) {
      name
      id
    }
  }
`;
@Injectable()
export class EditionService {
  private defaultEditionId = 2;
  private editions: CoreModule.Edition[] = [];
  currentEditionId = this.getEdition() || this.defaultEditionId;

  constructor(private apiService: ApiService) {}

  // this method should be only called once in app.component level
  public getValidEditionId() {
    // check the whether the editionId on initialization is valid or not
    // 1. if it is valid, we keep localStorage and url queryParams sync with it
    // 2. if it is not, we keep localStorage and url queryParams sync with a valid one and refresh the page

    this.getAllEditions().subscribe((editions = []) => {
      this.editions = editions;
      let editionId = this.currentEditionId;
      const isCurrentEditionIdInvalid = !this.editions.find(
        (edition) => edition.id === this.currentEditionId
      );

      // if the currentEditionId is invalid
      if (isCurrentEditionIdInvalid) {
        // take the first one of valid editions
        editionId =
          (this.editions.length > 0 && this.editions[0].id) ||
          this.defaultEditionId;
        // if the currentEditionId is invalid, use the first valid edition from edtions list
        this.setEdition(editionId);
      }
    });
  }

  public setEdition(editionId: number) {
    try {
      localStorage.setItem('editionId', String(editionId));
    } catch {
      console.error('Your browser does not support cookies');
    }
    window.location.reload();
  }

  private getEdition(): number | undefined {
    try {
      // if localStorage is enabled
      const editionId = localStorage.getItem('editionId');
      return editionId && Number(editionId);
    } catch {
      console.error('Cookies are disabled!');
    }
  }

  // data query

  public getAllEditions(division_id = 2): Observable<CoreModule.Edition[]> {
    return this.apiService.apollo
      .query({
        query: gql(`${getAllEditionsQuery}`),
        variables: { division_id }
      })
      .pipe(
        map((result) => {
          return (result.data as any).editions as CoreModule.Edition[];
        })
      );
  }
}
