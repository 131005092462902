import { debounceTime } from 'rxjs/operators';
import {
  Directive,
  HostListener,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  Input
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Directive({
  selector: '[appDebounceClick]'
})
export class DebounceClickDirective implements OnInit, OnDestroy {
  @Input() time = 300;

  @Output() debouncedClick = new EventEmitter();
  @Output() originClick = new EventEmitter();

  private $debouncedClickSubject = new Subject();
  private debouncedSubscription: Subscription;

  @HostListener('click', ['$event'])
  handleClick(e: any) {
    e.preventDefault();
    e.stopPropagation();

    this.originClick.emit(e);
    this.$debouncedClickSubject.next(e);
  }

  ngOnInit() {
    this.debouncedSubscription = this.$debouncedClickSubject
      .pipe(debounceTime(this.time))
      .subscribe((e) => this.debouncedClick.emit(e));
  }

  ngOnDestroy() {
    this.debouncedSubscription.unsubscribe();
  }
}
