import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  public constructor(private authService: AuthService) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.getJwtToken().pipe(
      switchMap((token: string) => {
        if (token) {
          request = request.clone({
            setHeaders: { Authorization: 'Bearer ' + `${token}` }
          });
        }
        const subscriptionId = localStorage.getItem('subscription-id');
        if(subscriptionId) {
          request.headers.append('subscription-id', subscriptionId);
        }

        return next.handle(request);
      })
    );
  }
}
