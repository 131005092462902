
import { distinctUntilChanged, debounceTime, tap } from 'rxjs/operators';
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';


import { Subscription } from 'rxjs';

type iconType = 'search';
@Component({
  selector: 'app-addons-input',
  templateUrl: './addons-input.component.html',
  styleUrls: ['./addons-input.component.sass']
})
export class AddonsInputComponent implements OnInit, OnDestroy {
  @Input() placeholder = '';
  @Input() showIcon = false;
  @Input() iconType: iconType = 'search';
  @Input() set value(value: string) {
    this.inputControl.setValue(value, { emitEvent: false });
    this.closeIconHidden = value.length === 0;
  }

  @Output() valueChange: EventEmitter<{value: string}> = new EventEmitter();

  inputControl = new FormControl();
  inputSubscription: Subscription;

  closeIconHidden = true;

  ngOnInit() {
    this.inputSubscription = this.inputControl.valueChanges.pipe(
      tap((value) => {
        this.closeIconHidden = value.length === 0;
      }),
      debounceTime(500),
      distinctUntilChanged() )
      .subscribe(value => {
        this.valueChange.emit({ value });
      });
  }

  clearFilter() {
    this.inputControl.setValue('');
  }

  ngOnDestroy() {
    this.inputSubscription.unsubscribe();
  }
}
