import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Observable, ReplaySubject } from 'rxjs';

import { ApiService } from '../api.service';
import { EditionService } from '../core/edition.service';
import { CircleChartData } from './circle-chart/circle-chart-data';
import { convert2UnixTimestamp, mapFrontendFiltersArguments2backend } from '../shared/utils';
import * as moment from 'moment';

const newIndicatorsEventsQuery = `
  query scenarioIndicators(
    $editionId: Int!,
    $rangeStart: Int,
    $rangeEnd: Int,
  ) {
    edition(id: $editionId){
      indicators: scenario_indicators {
        id
        data: scenario_news_and_news_aspects (date_start: $rangeStart, date_end: $rangeEnd) {
          stats {
            totalCount: current_query
          }
        }
        allBackendEvents: scenario_news_and_news_aspects {
          stats {
            totalCount: current_query
          }
        }
      }
    }
  }
`;
const listQuery = gql(`
  query getScenarios($editionID: Int!, $filters: String) {
    scenarios(edition_id: $editionID) {
      id
      name
      description
      probabilities(filters: $filters) {
        timestamp
        probability
      }
      influences {
        id
        weight
        direction
        indicator {
          id
          name
          description
        }
      }
    }
  }
`);

export interface RealizationRate {
  scenarioId: number;
  scenarioLabel: string;
  startProbability: number;
  endProbability: number;
}
type ProbabilityLookup = {
  [range in ScenarioModule.QuickRangeFilter]: RealizationRate[];
};

interface ScenarioChanges {
  newItems: ScenarioModule.NewIndicatorsEvents;
  comparedRange: ScenarioModule.QuickRangeFilter;
  realizationRate: RealizationRate[];
  isLoading: boolean;
}

@Injectable()
export class ScenarioService {
  scenarioChanges: Observable<ScenarioChanges>;
  rangeFilterOptionsForScenarioProbabilities: FilterModule.SelectItem[] = [
    { value: 'week', label: 'last week' },
    { value: 'twoWeeks', label: 'last 2 weeks' },
    { value: 'month', label: 'last month' },
    { value: 'all', label: 'the beginning' }
  ];

  get currentComparedRangeForScenarioProbability() {
    return this.currentScenarioChanges.comparedRange;
  }

  private scenarioChangesSubject = new ReplaySubject<ScenarioChanges>(1);
  private currentScenarioChanges = {
    // initial value
    comparedRange: 'week'
  } as ScenarioChanges;
  private scenarioProbabilitiesInAllRanges: ProbabilityLookup;
  private now = moment().valueOf();

  private get mappingLiteralTimeToTimestamp() {
    const lastWeekTimestamp = moment(this.now).subtract(1, 'week').valueOf();
    const lastTwoWeeksTimestamp = moment(this.now)
      .subtract(2, 'week')
      .valueOf();
    const lastMonthTimestamp = moment(this.now).subtract(1, 'month').valueOf();
    const rangeTimestamp: {
      [range in ScenarioModule.QuickRangeFilter]: number;
    } = {
      week: lastWeekTimestamp,
      twoWeeks: lastTwoWeeksTimestamp,
      month: lastMonthTimestamp,
      all: 0
    };
    return rangeTimestamp;
  }

  constructor(
    private apiService: ApiService,
    private editionService: EditionService
  ) {
    this.scenarioChanges = this.scenarioChangesSubject.asObservable();
    this.scenarioChanges.subscribe((scenarioChanges) => {
      this.currentScenarioChanges = scenarioChanges;
    });
  }

  public setTimeRange(comparedRange: ScenarioModule.QuickRangeFilter) {
    // start fetching data
    this.setScenarioChangesSubject({ isLoading: true });

    const unixRangeStart = convert2UnixTimestamp(
      this.mappingLiteralTimeToTimestamp[comparedRange]
    );
    const subscription = this.apiService.apollo
      .query({
        query: gql(newIndicatorsEventsQuery),
        variables: {
          editionId: this.editionService.currentEditionId,
          rangeStart: unixRangeStart
        }
      })
      .pipe(
        map((result) => {
          const indicators = (result as any).data.edition.indicators.map(
            (i: any) => ({
              id: i.id,
              stats: {
                ...i.data.stats,
                totalBackendCount: i.allBackendEvents.stats.totalCount
              }
            })
          ) as ScenarioModule.Indicator[];
          const eventCount = indicators.reduce((accEventCount, indicator) => {
            return indicator.stats.totalCount + accEventCount;
          }, 0);
          return {
            indicatorCount: indicators.length,
            eventCount
          };
        })
      )
      .subscribe((newIndicatorItems) => {
        this.setScenarioChangesSubject({
          // scenarioProbabilitiesInAllRanges may not set when first time calling this method
          // we will set isLoading to false in the list method until the scenarioProbabilitiesInAllRanges is ready when initialization
          isLoading: !this.currentScenarioChanges.realizationRate,
          comparedRange,
          newItems: newIndicatorItems,
          realizationRate: this.scenarioProbabilitiesInAllRanges
            ? this.scenarioProbabilitiesInAllRanges[comparedRange]
            : undefined
        });
        subscription.unsubscribe();
      });
  }

  public list(
    filters: ScenarioModule.AllFilters = {}
  ): Observable<ScenarioModule.Scenario[]> {
    const axes = {} as any;
    Object.keys(filters.axes).forEach((key) => {
      axes[key] = mapFrontendFiltersArguments2backend(filters.axes[key]);
    });
    const indicators = {} as any;
    Object.keys(filters.indicators).forEach((key) => {
      indicators[key] = mapFrontendFiltersArguments2backend(
        filters.indicators[key]
      );
    });

    this.setScenarioChangesSubject({ isLoading: true });
    return this.apiService.apollo
      .query({
        query: listQuery,
        variables: {
          editionID: this.editionService.currentEditionId,
          filters: JSON.stringify({
            timestamp: filters.timestamp,
            axes,
            indicators
          })
        }
      })
      .pipe(
        // new scenarios coming should also update the scenarioChanges
        tap((response) => {
          const scenarios = (response.data as any).scenarios;
          this.scenarioProbabilitiesInAllRanges = this.getScenariosProbabilities(
            scenarios
          );
          const scenarioProbabilitiesInCurrentRange = this
            .scenarioProbabilitiesInAllRanges[
              this.currentScenarioChanges.comparedRange
            ];

          // @TODO: filter change should also change the new events and new indicators number;
          this.setScenarioChangesSubject({
            realizationRate: scenarioProbabilitiesInCurrentRange,
            isLoading: !this.currentScenarioChanges.newItems
          });
        }),
        map((response) => {
          return (response.data as any).scenarios;
        })
      );
  }

  // utility function
  public convertScenariosToCircleChartData(
    scenarios: ScenarioModule.Scenario[]
  ): CircleChartData[] {
    return scenarios.map((scenario) => {
      let probability = scenario.initialProbability || 0;
      if (scenario.probabilities.length > 0) {
        probability =
          scenario.probabilities[scenario.probabilities.length - 1].probability;
      }
      return {
        name: scenario.name,
        description: scenario.description,
        probability: probability * 100
      };
    });
  }

  private setScenarioChangesSubject(options: Partial<ScenarioChanges>) {
    this.scenarioChangesSubject.next(
      Object.assign(this.currentScenarioChanges, options)
    );
  }
  private getScenariosProbabilities(scenarios: ScenarioModule.Scenario[]) {
    const probabilities: ProbabilityLookup = {
      all: [],
      month: [],
      week: [],
      twoWeeks: []
    };
    // move last item first
    const reorderedScenarios = scenarios ? [...scenarios] : [];
    if (reorderedScenarios.length > 0) {
      const shiftedItem = reorderedScenarios.pop();
      reorderedScenarios.unshift(shiftedItem);
    }
    scenarios = reorderedScenarios;

    scenarios.forEach((scenario) => {
      this.rangeFilterOptionsForScenarioProbabilities.forEach((item) => {
        const rangeKey = item.value as ScenarioModule.QuickRangeFilter;
        const relevantProbabilities = scenario.probabilities.filter(
          (probability) =>
            moment(probability.timestamp).valueOf() >
            this.mappingLiteralTimeToTimestamp[rangeKey]
        );

        if (relevantProbabilities.length > 0) {
          const firstItem = relevantProbabilities[0];
          const lastItem =
            relevantProbabilities[relevantProbabilities.length - 1];
          probabilities[rangeKey].push({
            scenarioId: scenario.id,
            scenarioLabel: scenario.name,
            startProbability: firstItem.probability,
            endProbability: lastItem.probability
          });
        }
      });
    });
    return probabilities;
  }
}
