export const convert2UnixTimestamp = (jsTimestamp: number) =>
  jsTimestamp ? Math.floor(jsTimestamp / 1000) : null;
export const getArgumentFromDateRange = (
  dateRange: NewsTable.Date,
  index: number
) => {
  return dateRange && convert2UnixTimestamp(dateRange[index]);
};

// filtering helper function
export function mapFrontendFiltersArguments2backend(
  filters: ScenarioModule.FilterArguments
) {
  return {
    title: filters.title,
    text: filters.text,
    date_start: getArgumentFromDateRange(filters.publishDate, 0),
    date_end: getArgumentFromDateRange(filters.publishDate, 1),
    thread_regions: filters.selectedRegions,
    thread_countries: filters.selectedCountries.map((c) => c.value),
    sources: filters.selectedSources.map((s) => s.value),
    source_rank_start: filters.sourceRank[0],
    source_rank_end: filters.sourceRank[1]
  };
}

export function ObjectNumberKeys(obj: { [key: number]: any }) {
  return Object.keys(obj).map(Number);
}
