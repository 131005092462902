import { Injectable, Inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';

import { AuthModel, AuthProviderToken } from '../../data';
import { map, take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private authModel: AuthModel;

  private oAuthProvider: firebase.auth.OAuthProvider;

  public constructor(
    @Inject(AuthProviderToken) private authProvider: string,
    private angularFireAuth: AngularFireAuth,
    private router: Router
  ) {
    this.oAuthProvider = new firebase.auth.OAuthProvider(this.authProvider);
    this.authModel = new AuthModel();
  }

  /**
   * checks if user is allready authorized
   */
  public isAuthorized(): Observable<boolean> {
    return this.angularFireAuth.user.pipe(
      map((user) => !!user),
      take(1)
    );
  }

  /**
   * get token or current user for request
   */
  public getJwtToken(): Observable<string> {
    return this.angularFireAuth.idToken;
  }

  /**
   * perform login action
   */
  public login(): void {
    this.angularFireAuth.auth.onAuthStateChanged((user) => {
      if (user) {
        this.authModel.user = user;
      } else {
        this.oAuthProvider.addScope('User.Read');
        this.angularFireAuth.auth.signInWithRedirect(this.oAuthProvider);
      }
    });
  }

  /**
   * get current user
   */
  public getUser(): Observable<firebase.User> {
    return this.angularFireAuth.user;
  }

  /**
   * perform logout action
   */
  public async logout(): Promise<void> {
    await this.angularFireAuth.auth.signOut();
    this.router.navigate(['login', localStorage.getItem('subscription-id')]);
  }
}
