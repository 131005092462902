<div class="filter-placeholder"> {{ name }} </div>
<p-calendar
  #dateCalendar
  [style]="{width: '100%', minWidth: '230px'}"
  [(ngModel)]="temporaryDateRange"
  selectionMode="range"
  [icon]="showCancelIcon ? 'fa fa-times' : 'fa fa-calendar'"
  [showIcon]="true"
  [readonlyInput]="true"
  dateFormat="yy-mm-dd"
  (onClose)="temporaryDateRange = storedDateRange">
  <p-footer>
    <div class="button-group">
      <button pButton label="Reset" (click)="resetDate()"></button>
      <button pButton label="Confirm" class="p-button--active" (click)="selectDate()"></button>
    </div>
  </p-footer>
</p-calendar>

<i  class="fa fa-times close-icon"
    [class.close-icon--hidden]="!showCancelIcon"
    aria-hidden="true"
    (click)="resetDate()"></i>
