import {
  Observable,
  Subscription,
  interval as observableInterval,
  fromEvent,
  combineLatest
} from 'rxjs';
import { map, startWith,  filter } from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import * as moment from 'moment';
import { CircleChartData } from '../charts/circle-chart/circle-chart-data';
import { ScenarioService } from '../charts/scenario.service';
import { ScenarioNewsService } from '../core/scenario-news.service';
import { ModalService } from '../modal.service';
import {
  CommunicationService,
  ScenarioChartEntityIds
} from '../core/communication.service';

interface OverViewCircleChartData extends CircleChartData {
  lastWeekProbability: number;
}
@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ScenarioService]
})
export class OverviewComponent implements OnInit, OnDestroy, AfterViewInit {
  public now: Observable<Date>;
  public data: OverViewCircleChartData[] = [];
  public isLoaded = false;
  recentAspects: ScenarioModule.OldNewsAspect[];
  isProbabilityDifferenceSignificantArray = [];
  isProbabilityDifferenceShown = false;
  globeMessageType: MessagesModule.EntityType = 'globe';
  globeEntityId = 1;
  developmentMessageType: MessagesModule.EntityType = 'scenario_news_charts';
  messages: Gnosis.MessageForCountNumber = {};
  entityIds = ScenarioChartEntityIds;

  strategyMessageType: MessagesModule.EntityType = 'strategic_options';
  strategyEntityId = 1;

  narration: string;

  @ViewChild('op') narrationPanelRef: any;

  private subscriptions: Subscription[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private scenarioService: ScenarioService,
    private modalService: ModalService,
    private scenarioNewsService: ScenarioNewsService,
    private communicationService: CommunicationService
  ) {
    this.now = observableInterval(1000).pipe(
      startWith(0),
      map(() => new Date())
    );
  }

  ngOnInit() {
    this.modalService.setGlobalLoadingState(true);

    const probabilitiesSubscription = this.scenarioService
      .list()
      .subscribe((scenarios) => {
        this.data = scenarios.map((scenario) => {
          let probabilityValue = scenario.initialProbability || 0;
          let lastWeekProbabilityValue = probabilityValue;

          if (scenario.probabilities.length > 0) {
            const probability =
              scenario.probabilities[scenario.probabilities.length - 1];
            const currentTimeStamp = probability.timestamp;
            const beginningOfSevenDaysAgo = moment(currentTimeStamp)
              .subtract(7, 'days')
              .valueOf();

            const lastweekProbability = scenario.probabilities.filter(
              (prob) => {
                return (
                  moment(prob.timestamp).valueOf() > beginningOfSevenDaysAgo
                );
              }
            )[0];
            lastWeekProbabilityValue = lastweekProbability.probability;

            probabilityValue = probability.probability;
          }
          return {
            name: scenario.name,
            description: scenario.description,
            probability: probabilityValue * 100,
            lastWeekProbability: lastWeekProbabilityValue * 100
          };
        });
        // the client require us to set delay to 6 seconds
        setTimeout(() => {
          this.modalService.setGlobalLoadingState(false);
          this.isLoaded = true;
          this.cd.markForCheck();
        }, 6000);

        this.isProbabilityDifferenceSignificantArray = this.data.map(
          (scenario) => {
            return (
              Math.abs(scenario.probability - scenario.lastWeekProbability) >
              0.5
            );
          }
        );
        this.isProbabilityDifferenceShown = this.isProbabilityDifferenceSignificantArray.find(
          (isSignificant) => {
            return isSignificant;
          }
        );
        this.cd.markForCheck();
      });
    this.subscriptions.push(probabilitiesSubscription);

    const newsSubscription = this.scenarioNewsService.news.subscribe(
      (results) => {
        this.recentAspects = results.items.reduce((aspects, newsItem) => {
          return aspects.concat(newsItem.aspects);
        }, []);
      }
    );
    const dayAgo = moment().subtract(1, 'day').valueOf();
    this.scenarioNewsService.getNews({
      limit: 1000,
      sources: [],
      indicators: [],
      title: '',
      text: '',
      publishDate: {
        start: dayAgo / 1000
      }
    });
    this.subscriptions.push(newsSubscription);

    const globeMessageCount$ = this.communicationService.listForEntityCount(
      this.globeMessageType,
      [this.globeEntityId]
    );
    const chartMessageCount$ = this.communicationService.listForEntityCount(
      this.developmentMessageType,
      [this.entityIds.CircleChart]
    );
    const strategicOptionsMessageCount$ = this.communicationService.listForEntityCount(
      this.strategyMessageType,
      [this.strategyEntityId]
    );

    const getMessagesSubscription = globeMessageCount$
      /* .pipe(combineLatest(chartMessageCount$, strategicOptionsMessageCount$)) */
      .pipe(f => combineLatest([f, chartMessageCount$, strategicOptionsMessageCount$]))
      .subscribe(
        ([
          globeMessageCount,
          chartMessageCount,
          strategicOptionsMessageCount
        ]) => {
          this.messages = Object.assign(
            {},
            globeMessageCount,
            chartMessageCount,
            strategicOptionsMessageCount
          );
          this.cd.markForCheck();
        }
      );
    this.subscriptions.push(getMessagesSubscription);

    const messageChangeSubscription = this.communicationService.$messagesChange.subscribe(
      (messageChange) => {
        this.messages = this.communicationService.updateMessageCount(
          this.messages,
          messageChange
        );
        this.cd.markForCheck();
      }
    );
    this.subscriptions.push(messageChangeSubscription);
  }

  ngAfterViewInit() {
    const scrollSubscription = fromEvent(
      document.body.querySelector('main'),
      'scroll'
    )
      .pipe(
        filter(() => {
          return this.narrationPanelRef.visible;
        })
      )
      .subscribe(() => {
        this.narrationPanelRef.hide();
        this.cd.detectChanges();
      });

    this.subscriptions.push(scrollSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // narrations
  openNarrationPanel(overlayPanel, $event, scenarioIndex: number) {
    this.narration = this.data[scenarioIndex].description;
    overlayPanel.show($event);
  }

  getMessageCount(
    id: number,
    entityType: MessagesModule.EntityType
  ): number | string {
    return this.communicationService.getMessageCountLabel(
      this.messages,
      entityType,
      id
    );
  }
}
