import { Injectable } from '@angular/core';

@Injectable()
export class TokenService {
  private token: Auth.Token;

  constructor() {
    // get the authentication token from local storage if it exists
    const serializedToken = this.retrieveToken();
    this.token = serializedToken && this.parseToken(serializedToken);
  }

  public getToken(): Auth.Token {
    return this.token;
  }

  public getAccessToken() {
    const token = this.getToken();
    return token && token.accessToken;
  }

  public getIDToken() {
    const token = this.getToken();
    return token && token.idToken;
  }

  public storeToken(token: Auth.Token) {
    this.token = token;
    try {
      localStorage.setItem('token', JSON.stringify(token));
    } catch (e) {
      console.error('Cookie is disabled');
    }
  }

  clearToken() {
    this.token = undefined;
    try {
      if (localStorage.getItem('token')) {
        localStorage.removeItem('token');
      }
    } catch (e) {
      console.error('Cookie is disabled');
    }
  }

  private retrieveToken(): string {
    try {
      return localStorage.getItem('token');
    } catch (e) {
      console.error('Cookie is disabled');
    }
  }

  private parseToken(serializedToken: string) {
    try {
      return JSON.parse(serializedToken);
    } catch {
      this.clearToken();
      console.warn('The token parsing has failed');
    }
  }
}
