import { map, take } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { UsersService } from '../core/users.service';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AuthService } from '../auth/src/utils';

@Injectable()
export class AuthGuard implements CanActivate {
  authService: AuthService;

  authMethod: string = localStorage.getItem('authMethod');

  authProvidersCount: number = Object.keys(environment.auth).length;

  constructor(
    private userService: UsersService,
    private router: Router,
    private injector: Injector
  ) {
    if (environment.auth.firebaseProvider) {
      this.authService = this.injector.get<AuthService>(AuthService); // inject authservice only when firebase authentication is enabled.
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (
      // If the authentication method is cognito or local backend
      this.authMethod === 'COGNITO' ||
      (!this.authMethod &&
        environment.auth.localProvider &&
        this.authProvidersCount === 1) ||
      (environment.auth.cognitoProvider && this.authProvidersCount === 1) ||
      this.authMethod === 'LOCAL'
    ) {
      return this.userService.user.pipe(
        map((user) => {
          if (user) {
            return true;
          }
          this.userService.login();
          return false;
        }),
        take(1)
      );
    } else if (
      // else if the authentication method is firebase
      this.authMethod === 'FIREBASE' ||
      (this.authProvidersCount === 1 && environment.auth.firebaseProvider)
    ) {
      return this.authService.isAuthorized().pipe(
        tap((isAuthorized) => {
          if (!isAuthorized) {
            this.router.navigate(['/login'], {
              queryParams: { returnUrl: state.url }
            });
          }
        })
      );
    }
  }

  canLoad(): Observable<boolean> {
    // return value of canLoad must be a already completed observable
    // see discussion in github, https://github.com/angular/angular/issues/18991

    if (
      // If the authentication method is cognito or local backend
      this.authMethod === 'COGNITO' ||
      (!this.authMethod &&
        environment.auth.localProvider &&
        this.authProvidersCount === 1) ||
      (environment.auth.cognitoProvider && this.authProvidersCount === 1) ||
      this.authMethod === 'LOCAL'
    ) {
      return this.userService.user.pipe(
        map((user) => {
          if (user) {
            return true;
          }
          this.userService.login();
          return false;
        }),
        take(1)
      );
    } else if (
      // else if the authentication method is firebase
      this.authMethod === 'FIREBASE' ||
      (this.authProvidersCount === 1 && environment.auth.firebaseProvider)
    ) {
      return this.authService.isAuthorized().pipe(
        tap((isAuthorized) => {
          return isAuthorized;
        })
      );
    }
  }
}
