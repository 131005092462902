<div class="filter-placeholder">
  <span>{{name}}</span>
  <span
    class="filter-placeholder__reset"
    [class.filter-placeholder__reset--hidden]="!showResetText"
    (click)="resetFilter()">Clear</span>
</div>
<p-multiSelect
  maxSelectedLabels=1
  [filterPlaceHolder]="getFilterPlaceholder()"
  [options]="allItems"
  [(ngModel)]="selectedItemsValues"
  optionLabel="label"
  [style]="{width: '100%', whiteSpace: 'nowrap'}"
  [panelStyle]="{width:'100%'}"
  (onPanelShow)="isDropdownHidden = false"
  (onPanelHide)="isDropdownHidden = true"
  [dropdownIcon]="isDropdownHidden ? 'fa fa-angle-down' : 'fa fa-angle-up'"
  (onChange)="applyFilter()">
</p-multiSelect>

<i  class="fa fa-times close-icon"
    [class.close-icon--hidden]="!isCancelIconShowing"
    aria-hidden="true"
    (click)="resetFilter()"></i>


