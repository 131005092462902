import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';

import { Observable } from 'rxjs';
import { UsersService } from '../core/users.service';

@Injectable()
export class ManageLibraryGuard implements CanActivate {
  constructor(
    private userService: UsersService,
    private router: Router,
    private permissionService: NgxPermissionsService
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.userService.user.pipe(
      switchMap(() => this.permissionService.hasPermission('manage_library')),
      map((canActivate) => {
        if (canActivate) {
          return true;
        }

        this.router.navigate(['overview']);
        return canActivate;
      })
    );
  }
}
