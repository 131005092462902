import { debounceTime } from 'rxjs/operators';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  HostListener,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import {
  ITreeOptions,
  ITreeState,
  TreeModel,
  TreeNode,
  TreeComponent
} from 'angular-tree-component';
import { ReplaySubject, Observable, Subscription } from 'rxjs';

import { ITreeNode } from 'angular-tree-component/dist/defs/api';

export interface TreeSelectItem extends FilterModule.SelectItem {
  isFolder?: boolean;
  children?: TreeSelectItem[];
}

@Component({
  selector: 'app-tree-select-items',
  templateUrl: './tree-select-items.component.html',
  styleUrls: ['./tree-select-items.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeSelectItemsComponent implements OnInit, OnDestroy {
  @Input() name = '';
  isDropdownShowing = true;
  isResetButtonShowing = false;
  isCancelIconShowing = false;

  @Input() set selectedItems(selectedItems: FilterModule.SelectItem[]) {
    this._selectedItems = selectedItems;

    const selectedLeafNodes = {};
    // update tree state
    selectedItems.forEach((i) => {
      selectedLeafNodes[i.value] = true;
    });
    this.state = {
      ...this.state,
      selectedLeafNodeIds: selectedLeafNodes
    };

    // update labels
    // using settimeout make sure the tree is created when initialization
    // & also ensure the child labels update correctly after child tree state is updated
    setTimeout(() => {
      this.updateLabels(selectedItems);

      // update the visual state of clear button
      this.isResetButtonShowing = this.labels.length > 1;
      this.isCancelIconShowing = this.labels.length === 1;
      this.cd.markForCheck();
    }, 0);
  }
  get selectedItems() {
    return this._selectedItems;
  }
  private _selectedItems: FilterModule.SelectItem[];

  @Input() set options(items: TreeSelectItem[]) {
    this.nodes = this.setNodes(items);
  }

  // optional input property
  @Input() showChips = true;
  @Input() maxSelectedLabels = 1;
  labels: FilterModule.SelectItem[] = [];
  get labelText() {
    if (this.labels.length === 0) {
      return 'Regions contain multiple countries';
    } else if (this.labels.length === 1) {
      return this.labels[0].label;
    } else {
      return `${this.labels.length} items are selected`;
    }
  }

  // tree
  state: ITreeState;
  nodes: TreeSelectItem[] = [];

  @ViewChild('tree') treeComponent: TreeComponent;

  treeOptions: ITreeOptions = {
    useCheckbox: true,
    // is you change the value here, make sure check all relevant field manually,
    // since treeModel doesn't support generic type to check it automatically
    idField: 'value',
    displayField: 'label'
  };

  @Output() filterChange = new EventEmitter<{
    items: FilterModule.SelectItem[];
  }>();
  private selectedLeafNodesSubject = new ReplaySubject<
  FilterModule.SelectItem[]
  >(1);
  private $selectedLeafNodes: Observable<FilterModule.SelectItem[]>;
  private subscription: Subscription = new Subscription();

  @ViewChild('infoPanel') private infoPanel: ElementRef;
  @ViewChild('dropdownPanel') private dropdownPanel: ElementRef;

  @HostListener('window:click', ['$event'])
  onClick(e) {
    const target = e.target;
    if (
      this.infoPanel.nativeElement.contains(target) ||
      this.dropdownPanel.nativeElement.contains(target)
    ) {
      return;
    }
    this.isDropdownShowing = false;
  }

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.$selectedLeafNodes = this.selectedLeafNodesSubject.asObservable();
    const subscription = this.$selectedLeafNodes
      .pipe(debounceTime(50))
      .subscribe((node) => {
        this.filterChange.emit({ items: node });
      });
    this.subscription.add(subscription);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toggleDropdown() {
    this.isDropdownShowing = !this.isDropdownShowing;
    if (!this.isDropdownShowing) {
      this.treeComponent.treeModel.collapseAll();
    }
  }

  closeDropdown() {
    this.isDropdownShowing = false;
    this.treeComponent.treeModel.collapseAll();
  }

  onSelect($event) {
    let selectedNodes = [];
    Object.keys($event.treeModel.selectedLeafNodeIds).forEach((key) => {
      if ($event.treeModel.selectedLeafNodeIds[key]) {
        selectedNodes.push(key);
      }
    });
    selectedNodes = selectedNodes.map((id) => {
      const node = this.treeComponent.treeModel.getNodeById(id);
      return {
        value: node.data.value,
        label: node.data.label
      };
    });
    this.selectedLeafNodesSubject.next(selectedNodes);
  }

  resetFilter() {
    this.selectedLeafNodesSubject.next([]);
  }

  filterFn(value: string, treeModel: TreeModel) {
    treeModel.filterNodes((node: TreeNode) =>
      this.fuzzysearch(value, node.data.label)
    );
  }

  onChipRemove(chip: FilterModule.SelectItem) {
    const node = this.treeComponent.treeModel.getNodeById(chip.value);
    const leafNodesWhichShouldBeRemoved = [];
    const recursivelyFindRemovingLeafNodes = (n: TreeNode) => {
      if (n.children) {
        n.children.forEach((child) => recursivelyFindRemovingLeafNodes(child));
      } else {
        leafNodesWhichShouldBeRemoved.push(n);
      }
    };
    recursivelyFindRemovingLeafNodes(node);
    let result = [...this.selectedItems];
    leafNodesWhichShouldBeRemoved
      .map((n) => n.data)
      .forEach((item) => {
        result = result.filter((i) => i.value !== item.value);
      });

    this.selectedLeafNodesSubject.next(result);
  }

  // from official example
  // https://github.com/500tech/angular-tree-component/blob/master/example/cli/src/app/filter/filter.component.ts
  private fuzzysearch(needle: string, haystack: string) {
    const haystackLC = haystack.toLowerCase();
    const needleLC = needle.toLowerCase();

    const hlen = haystack.length;
    const nlen = needleLC.length;

    if (nlen > hlen) {
      return false;
    }
    if (nlen === hlen) {
      return needleLC === haystackLC;
    }
    outer: for (let i = 0, j = 0; i < nlen; i++) {
      const nch = needleLC.charCodeAt(i);

      while (j < hlen) {
        if (haystackLC.charCodeAt(j++) === nch) {
          continue outer;
        }
      }
      return false;
    }
    return true;
  }

  private setNodes(data: TreeSelectItem[]) {
    const recursiveSignningNodes = (items) => {
      items.forEach((i) => {
        if (i.children) {
          i.isFolder = true;
          recursiveSignningNodes(i.children);
        } else {
          i.isFolder = false;
        }
      });
    };
    recursiveSignningNodes(data);
    return data;
  }

  private updateLabels(selectedItems: FilterModule.SelectItem[]) {
    const nodes = selectedItems.map((item) =>
      this.treeComponent.treeModel.getNodeById(item.value)
    );
    this.labels = this.getLabels(nodes);
  }

  private getLabels(data: ITreeNode[]) {
    const finalLabels = [];
    const recursiveSettingLabels = (nodes: ITreeNode[] = []) => {
      const resultNodes: ITreeNode[] = [];
      nodes.forEach((node) => {
        // condition: node.parent.parent is used to escape the top level virtual root node
        if (
          node.parent &&
          (node.parent as any).isAllSelected &&
          node.parent.parent
        ) {
          // need deep check
          if (!resultNodes.find((rn) => rn.id === node.parent.id)) {
            resultNodes.push(node.parent);
          }
        } else {
          // here are nodes whose parents is not all selected.
          // i.e. we should show it directly
          finalLabels.push(node.data as FilterModule.SelectItem);
        }
      });

      // if there are some nodes which need to be checked
      if (resultNodes.length > 0) {
        recursiveSettingLabels(resultNodes);
      }
    };
    recursiveSettingLabels(data);
    return finalLabels;
  }
}
