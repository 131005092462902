import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { environment } from '../environments/environment';
import { FetchPolicy, ErrorPolicy } from 'apollo-client';
import { TokenService } from './token.service';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { Router } from '@angular/router';

export const backendResourceModelFields = `
  createdAt: created_at,
  deletedAt: deleted_at
  updatedAt: updated_at
`;

@Injectable()
export class ApiService {
  graphqlUrl: string;
  authMethod: string = localStorage.getItem('authMethod');

  constructor(
    public apollo: Apollo,
    httpLink: HttpLink,
    private tokenService: TokenService,
    private router: Router
  ) {
    if (!this.authMethod) {
      // if app is running on local backend with no authentication
      if (
        environment.auth.cognitoProvider &&
        !environment.auth.firebaseProvider
      ) {
        this.graphqlUrl = environment.auth.cognitoProvider.graphqlUrl;
      } else if (
        !environment.auth.cognitoProvider &&
        environment.auth.firebaseProvider
      ) {
        this.graphqlUrl = environment.auth.firebaseProvider.graphqlUrl;
      } else {
        this.graphqlUrl = environment.auth.localProvider.graphqlUrl;
      }
    } else if (
      this.authMethod === 'COGNITO' &&
      environment.auth.cognitoProvider
    ) {
      this.graphqlUrl = environment.auth.cognitoProvider.graphqlUrl;
    } else if (
      this.authMethod === 'FIREBASE' &&
      environment.auth.firebaseProvider
    ) {
      this.graphqlUrl = environment.auth.firebaseProvider.graphqlUrl;
    } else if (this.authMethod === 'LOCAL' && environment.auth.localProvider) {
      this.graphqlUrl = environment.auth.localProvider.graphqlUrl;
    }

    const url = httpLink.create({
      uri: this.graphqlUrl
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if(networkError) {
        if (networkError['status'] >= 400) {
          this.router.navigate(['/insufficient-permissions']);
        }
      }
    });

    /*
    // Create a WebSocket link:
    const ws = new WebSocketLink({
      uri: environment.websocketUrl,
      options: {
        reconnect: true
      }
    });

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    const link = split(
      // split based on operation type
      ({ query }) => {
        const request = getMainDefinition(query);
        return request.kind === 'OperationDefinition' && request.operation === 'subscription';
      },
      ws,
      http
    );
    */

    // default options
    const defaultOptions = {
      watchQuery: {
        fetchPolicy: 'cache-first' as FetchPolicy,
        errorPolicy: 'ignore' as ErrorPolicy
      },
      query: {
        fetchPolicy: 'cache-first' as FetchPolicy,
        // NOTE: The backend returns many errors for missing data
        // therefore we ignore them
        errorPolicy: 'ignore' as ErrorPolicy
      }
    };

    // options with no cache strategy
    const noCacheOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache' as FetchPolicy,
        errorPolicy: 'ignore' as ErrorPolicy
      },
      query: {
        fetchPolicy: 'no-cache' as FetchPolicy,
        // NOTE: The backend returns many errors for missing data
        // therefore we ignore them
        errorPolicy: 'ignore' as ErrorPolicy
      }
    };

    const httpLinkWithErrorHandling = ApolloLink.from([
      errorLink,
      this.getAuthHeader().concat(url)
    ]);
    apollo.create(
      {
        link: httpLinkWithErrorHandling,
        defaultOptions,
        cache: new InMemoryCache()

      },
      'default'
    );

    apollo.create(
      {
        link: httpLinkWithErrorHandling,
        defaultOptions: noCacheOptions,
        cache: new InMemoryCache()
      },
      'no-cache'
    );
  }

  getAuthHeader() {
    return setContext((_, { headers }) => {
      const token = this.tokenService.getIDToken();
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : ''
        }
      };
    });
  }
}
