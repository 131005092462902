import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MentionModule } from 'angular-mentions';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { OverviewModule } from './overview/overview.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RootComponent } from './root/root.component';
import { SharedModule } from './shared/shared.module';
import { FirebaseOptions } from '@angular/fire';
import { AiHubAuthModule } from './auth';
import { environment } from '../environments/environment';
import { LoginComponent } from './login/login';
import { InsufficientPermissionsComponent } from './insufficient-permissions/insufficient-permissions.component';

const arrModules: any[] = [MentionModule]; // The array is initialized with MentionModule so that its never empty

/* Include the authentication module only when there is firebase provider in configuration file */
if (environment.auth.firebaseProvider) {
  const firebaseConfiguration: FirebaseOptions = {
    apiKey: environment.auth.firebaseProvider
      ? environment.auth.firebaseProvider.firebase.apiKey
      : '',
    authDomain: environment.auth.firebaseProvider
      ? environment.auth.firebaseProvider.firebase.authDomain
      : '',
    projectId: environment.auth.firebaseProvider
      ? environment.auth.firebaseProvider.firebase.projectId
      : ''
  };

  arrModules.push(
    AiHubAuthModule.forRoot({
      /** name of the auth provider for example */
      authProvider: environment.auth.firebaseProvider.authProvider,
      // authProvider: 'oidc.azure',

      /** firebase options */
      firebase: firebaseConfiguration,
      /** go to this page after success login */
      redirectUri: environment.auth.firebaseProvider.redirectUri
    })
  );
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    InsufficientPermissionsComponent,
    RootComponent,
    LoginComponent
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    CoreModule,
    FormsModule,
    SharedModule,
    OverviewModule,
    ...arrModules, // include each item in the array
    NgxPermissionsModule.forRoot()
  ],
  bootstrap: [RootComponent]
})
export class AppModule {}
