  <div class="jumbotron" *ngIf="!userLoggedIn && !isLoading">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 offset-sm-3">
          <img
            src="assets/deloitte_full_3.png"
            height="40"
            class="d-inline-block align-top"
            alt=""
          />
        <!--<h2>Undial <span style="color:#92d134">Tool</span> Login</h2>-->
          <h2>Gnosis <span style="color:#92d134">Tool</span> Login</h2>
          <form >
            <p class="card-text">Login with corporate account</p>

            <!-- <div class="form-group">
              <label for="username">Username</label>
              <input
                type="text"
                formControlName="username"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
              />
              <div class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Username is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input
                type="password"
                formControlName="password"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
              />
              <div
                *ngIf="submitted && f.password.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.password.errors.required">Password is required</div>
              </div>
            </div> -->
            <div class="form-group">
              <button id="test" type="button" (click)="doLogin()" class="btn btn-success" *ngIf="showFirebaseLogin" fxShow fxHide.lt-md >Login with Deloitte.AI</button>
              <button id="test" type="button" (click)="doLoginWithCognito()" *ngIf="showCognitoLogin" class="btn btn-success" fxShow fxHide.lt-md>Login with Cognito</button>
              <button id="test" type="button" (click)="doLocalLogin()" *ngIf="showLocalLogin" class="btn btn-success" fxShow fxHide.lt-md>Local Login</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
