import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-more-options-filters',
  templateUrl: './more-options-filters.component.html',
  styleUrls: ['./more-options-filters.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoreOptionsFiltersComponent {
  @Input() storedMultipleFilters: NewsTable.MoreOptionFilters;
  @Input() $allCountries: Observable<{ name: string; value: string }[]>;
  @Output() activateMultipleFilters = new EventEmitter<{
    multipleFilters: NewsTable.MoreOptionFilters;
  }>();
  isMultipleFiltersShowing = false;

  form = new FormGroup({
    location: new FormControl(),
    organization: new FormControl(),
    person: new FormControl(),
    country: new FormControl(),
    siteType: new FormControl(),
    author: new FormControl(),
    language: new FormControl()
  });

  // for chips
  get enabledFilters() {
    return Object.keys(this.storedMultipleFilters)
      .filter((key) => {
        if (!this.storedMultipleFilters[key]) {
          return false;
        }
        // for array filters
        return this.storedMultipleFilters[key].length !== 0;

      })
      .map((key) => {
        return { key, value: this.storedMultipleFilters[key] };
      });
  }
  constructor(private cd: ChangeDetectorRef) {}

  initialFilters() {
    this.form.setValue({
      location: this.storedMultipleFilters.location,
      organization: this.storedMultipleFilters.organization,
      person: this.storedMultipleFilters.person,
      country: this.storedMultipleFilters.country,
      siteType: this.storedMultipleFilters.siteType,
      author: this.storedMultipleFilters.author,
      language: this.storedMultipleFilters.language
    });
  }
  // because component will not be deleted and recreated, we need to manually check initial state here
  onDialogShowing() {
    this.initialFilters();
    setTimeout(() => {
      this.cd.detectChanges();
    }, 0);
  }

  applyMultipleFilters() {
    Object.keys(this.storedMultipleFilters).forEach((key) => {
      this.storedMultipleFilters[key] = this.form.get(key).value;
    });

    this.isMultipleFiltersShowing = false;

    this.activateMultipleFilters.emit({
      multipleFilters: Object.assign({}, this.storedMultipleFilters)
    });
  }

  resetAllFilters() {
    this.form.setValue({
      location: '',
      organization: '',
      person: '',
      country: [],
      siteType: '',
      author: '',
      language: ''
    });
  }

  onChipsFilterDelete(disabledFilterKey: string) {
    Object.keys(this.storedMultipleFilters).forEach((key) => {
      if (key === disabledFilterKey) {
        if (key === 'country') {
          this.storedMultipleFilters[key] = [];
        } else {
          this.storedMultipleFilters[key] = '';
        }
      }
    });
    this.activateMultipleFilters.emit({
      multipleFilters: Object.assign({}, this.storedMultipleFilters)
    });
  }
}
