import { map, skip } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { QueryRef } from 'apollo-angular';
import gql from 'graphql-tag';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';

import { ApiService } from '../api.service';
import { EditionService } from './edition.service';
import { EditModeService } from './edit-mode.service';
import { TreeSelectItem } from '../shared/tree-select-items/tree-select-items.component';
import { convert2UnixTimestamp, getArgumentFromDateRange, mapFrontendFiltersArguments2backend } from '../shared/utils';

export type ToolTipKey =
  | 'SourceRankAverage'
  | 'AggregatedScenarioDirection'
  | 'Reach';
export const X_AXIS_DIMENSION = 'X';
export const Y_AXIS_DIMENSION = 'Y';

const toolTipInformation: { [key in ToolTipKey]: string } = {
  SourceRankAverage:
  // eslint-disable-next-line max-len
    '<b>Source Tier describes the reputability of the news outlet the event is published in on the scale of 1-5, where 1 are the most respected news sources and 5 are non-news sources, such as message boards.</b>',
  AggregatedScenarioDirection:
  // eslint-disable-next-line max-len
    '<b>Indicates the aggregated scenario direction of all events assigned to the indicator.</b> Scenario direction shows the direction the event influences the likelihood and the strength of future scenarios along an axis',

  Reach:
  // eslint-disable-next-line max-len
    'Reach is based on MozRank. MozRank is presented on a scale of 0 (lowest) to 10 (highest). The more other sites backlink to a web page, the higher the MozRank.'
};

export const scenarioNewsBasicFields = `
  createdAt: created_at
  domainRank: domain_rank
  sourceRank: source_rank
  editionId: edition_id
  id
  language
  location
  organization
  popularity: performance_score
  person
  publishDate: publish_date
  source
  state
  threadCountry: thread_country
  threadRegion: thread_region
  title
  url
  licenses
  # author
  # category
`;

export const scenarioNewsAspectFragment = `
  fragment scenarioNewsAspect on ScenarioNewsAspect {
    id
    snippet
    score
    state
    indicator {
      id
      name
      axis {
        id
        name
        dimension
      }
    }

    metaGroupId: meta_group_id
    metaGroupCount: meta_group_count
    metaGroupPeriod: meta_group_period
    metaGroupPreviousPeriod: meta_group_previous_period
  }
`;

const scenarioIndicatorStatsFragment = `
  fragment scenarioIndicatorStats on ScenarioIndicatorStats {
    totalCount: current_query
    averagePopularity: scenario_news_popularity_avg
    averageScore: scenario_news_aspect_score_avg
  }
`;

// TODO: Delete this Fragement after we update all query(query in feed/library/globe module) with new API
export const oldScenarioNewsFragment = `
  fragment oldScenarioNewsFragment on ScenarioNews {
    aspects {
      ...scenarioNewsAspect
    }
    ${scenarioNewsBasicFields}
  }

  ${scenarioNewsAspectFragment}
`;

const scenarioNewsFragment = `
  fragment scenarioNewsFragment on ScenarioNews {
    ${scenarioNewsBasicFields}
  }
`;

const scenarioNewsTopArticlesFragments = `
  fragment topArticles on ScenarioNews {
    id
    title
    publishDate: publish_date
    text: text_preview
    source: source_uuid
    url
    licenses
  }
`;

const scenarioAxesInfoQuery = `
  query scenarioAxesInfo($editionId: Int!){
    edition(id: $editionId){
      name
      description
      scenarioAxis: scenario_axis {
        id
        name
        dimension
        descriptionNegative: description_min
        descriptionPositive: description_max
      }
    }
  }
`;

const scenarioFilterOptionsType = `
  $limit: Int,
  $offset: Int,
  $text: String,
  $title: String,
  $date_start: Int,
  $date_end: Int,
  $thread_regions: [String],
  $thread_countries: [String],
  $sources: [String],
  $source_rank_start: Int,
  $source_rank_end: Int,
  $topNewsLimit: Int,

  $metaGroupId: String,
  $metaGroupPreviousPeriod: Boolean,

  $backendRangeStart: Int,
  $backendRangeEnd: Int
`;

const scenarioFilteringOptionsWithoutTimeframe = `
  limit: $limit,
  offset: $offset,
  text: $text,
  title: $title,
  thread_regions: $thread_regions,
  thread_countries: $thread_countries,
  sources: $sources,
  source_rank_start: $source_rank_start,
  source_rank_end: $source_rank_end,
  top_news_limit: $topNewsLimit,

  meta_group_id: $metaGroupId,
  meta_group_previous_period: $metaGroupPreviousPeriod
`;

const scenarioFilterOptionsArguments = `
  ${scenarioFilteringOptionsWithoutTimeframe},
  date_start: $date_start,
  date_end: $date_end,
`;

export const allBackendEvents = `
  allBackendEvents: scenario_news_and_news_aspects(
    ${scenarioFilteringOptionsWithoutTimeframe},
    date_start: $backendRangeStart,
    date_end: $backendRangeEnd
  ) {
    stats {
      totalCount: current_query
    }
  }
`;

const scenarioIndicatorsQuery = `
  query scenarioIndicators
    ($editionId: Int!,
    $dimensions: [String],
    ${scenarioFilterOptionsType}
    ) {
    edition(id: $editionId){
      scenarioAxis: scenario_axis(dimensions: $dimensions){
        id
        indicators {
          weight
          name
          key
          label
          id
          type
          isLexisNexisData: lexisNexisData
          order: order_id
          data: scenario_news_and_news_aspects
            (${scenarioFilterOptionsArguments}) {
            aspects: news_aspects{
              ...scenarioNewsAspect
              scenarioNews: scenario_news {
                ...scenarioNewsFragment
              }
            }

            stats {
              ...scenarioIndicatorStats
            }

            topNews: top_news {
              ...topArticles
            }
          }
          axis {
            id
            name
            dimension
          }

          ${allBackendEvents}
        }
      }
    }
  }
  ${scenarioNewsAspectFragment}
  ${scenarioNewsFragment}
  ${scenarioNewsTopArticlesFragments}
  ${scenarioIndicatorStatsFragment}
`;

const scenarioNewsAspectQuery = `
  query scenarioAspects(
    $indicatorId: Int!,
    $sortOrder: String,
    $sortProperty: String,
    ${scenarioFilterOptionsType})
      {
        indicator(id: $indicatorId) {
          id
          data: scenario_news_and_news_aspects(
              sort_order: $sortOrder,
              sort_property: $sortProperty,
              ${scenarioFilterOptionsArguments}){
            aspects: news_aspects
              {
                ...scenarioNewsAspect
                scenarioNews: scenario_news {
                  ...scenarioNewsFragment
                }
              }
            stats {
              ...scenarioIndicatorStats
            }
            topNews: top_news {
              ...topArticles
            }
          }
          ${allBackendEvents}
        }
      }
      ${scenarioNewsAspectFragment}
      ${scenarioNewsFragment}
      ${scenarioNewsTopArticlesFragments}
      ${scenarioIndicatorStatsFragment}
`;

const scenarioNewsAspectExtendedSnippetQuery = `
  query scenarioNewsAspectExtendedSnippet($id: Int){
    scenarioNewsAspect(id: $id) {
      id
      extendedSnippet: extended_snippet{
        snippet,
        highlightStart: highlight_start,
        highlightEnd: highlight_end
      }
    }
  }
`;

const COMMENTS_SUBSCRIPTION = gql`
  subscription onNewsAdded {
    newsAdded {
      ...oldScenarioNewsFragment
    }
  }
  ${oldScenarioNewsFragment}
`;

const updateNewsAspectMutation = `
  mutation updateNewsAspectMutation($id: Int!, $editionID: Int!, $state: String) {
    updateScenarioNewsAspect(id: $id, edition_id: $editionID, state: $state) {
      id
      state
    }
  }
`;

const updateIndicatorWeightMutation = `
  mutation updateIndicatorWeight($id:Int!, $weight:Float) {
    updateScenarioIndicator(id:$id, weight:$weight) {
      id
      weight
    }
  }
`;

const getSingleNewsQuery = `
  query getSingleNews($id: Int!) {
    scenarioSingleNews(id: $id) {
      title
      text
      source
      publishedDate: publish_date
      downloadedAt: crawled_date
    }
  }
`;

export const isLexisNexisContent = (licenses: string[]) => {
  return licenses.indexOf('LEXISNEXIS_LICENSED') > -1;
};

@Injectable()
export class ScenarioNewsService {
  // TODO: Delete these five variables after we update all query(query in feed/library/globe module) with new API
  news: Observable<ScenarioModule.OldScenarioNewsListResponse>;
  currentNews: ScenarioModule.OldScenarioNews[];
  currentTotal: number;
  private newsQuery: QueryRef<any>;
  private newsSubject = new BehaviorSubject({ items: [], total: 0 });

  // indicators
  indicatorsList: Observable<ScenarioModule.Indicator[]>;
  private indicatorsListSubject = new ReplaySubject<ScenarioModule.Indicator[]>(
    1
  );

  // @TODO: shareable state - deleted it since we don't use file system any more in this app
  private selectedIndicatorsSubject = new BehaviorSubject<string[]>([]);
  selectedIndicators: Observable<string[]>;

  // NOTE: subscriptions are disabled since the backend doesn't support it
  private isSubscriptionStarted = true;

  private editMode = 'view';
  private subscriptions: Subscription[] = [];

  constructor(
    private apiService: ApiService,
    private editionService: EditionService,
    public editModeService: EditModeService,
    private sanitizer: DomSanitizer
  ) {
    // TODO: Delete this line after we update all query(query in feed/library/globe module) with new API
    this.news = this.newsSubject.asObservable().pipe(skip(1));

    this.indicatorsList = this.indicatorsListSubject.asObservable();
    this.selectedIndicators = this.selectedIndicatorsSubject.asObservable();

    const editModeSubscription = this.editModeService.editMode.subscribe(
      (isInEditMode) => (this.editMode = isInEditMode ? 'edit' : 'view')
    );
    this.subscriptions.push(editModeSubscription);
  }

  // scenario axis info
  public getScenarioAxesInfo(): Observable<{
    axes: ScenarioModule.ScenarioAxis[];
    editionDescription: string;
  }> {
    return this.apiService.apollo
      .query({
        query: gql(`${scenarioAxesInfoQuery}`),
        variables: { editionId: this.editionService.currentEditionId }
      })
      .pipe(
        map((result) => {
          const edition = (result.data as any).edition;
          return {
            axes: edition.scenarioAxis,
            editionDescription: edition.description
          };
        })
      );
  }

  // indicators data querying
  public getIndicators(options: ScenarioModule.IndicatorsRequestOptions): void {
    const indicatorsSubscription = this.apiService.apollo
      .query({
        query: gql(`
        ${scenarioIndicatorsQuery}
      `),

        variables: {
          editionId: this.editionService.currentEditionId,
          dimensions: [options.axisDimension],
          ...this.getScenarioFiltersOptionsArguments(options),
          backendRangeStart: convert2UnixTimestamp(options.publishDate[0]),
          backendRangeEnd: convert2UnixTimestamp(options.publishDate[1])
        }
      })
      .pipe(
        map((result) => {
          const edition = (result.data as any).edition;

          // 1. make indicators mutable, so that we allow change per indicator(child updates from each scenario-news-table)
          // this feature will be available in the future version of apollo. Since we use a old version, we need to hack here.\
          // 2. also adapt the api interface here
          const makeIndicatorsMutable = (i: any) => {
            return {
              ...i,
              weight: i.weight,
              aspects:
                i.data && i.data.aspects
                  ? i.data.aspects.map(
                    (
                      a: ScenarioModule.ScenarioNewsAspectWithScenarioNews
                    ) => ({ ...a, state: a.state })
                  )
                  : [],
              stats: {
                ...i.data.stats,
                totalBackendCount: i.allBackendEvents.stats.totalCount
              },
              topNews: i.data.topNews,
              data: null
            };
          };

          // indicators are supposed to be indicatorId sorted already
          return edition.scenarioAxis[0].indicators
            .map(makeIndicatorsMutable)
            .sort(
              (i: ScenarioModule.Indicator, j: ScenarioModule.Indicator) =>
                i.order - j.order
            );
        })
      )
      .subscribe((data) => {
        this.indicatorsListSubject.next(data);
        indicatorsSubscription.unsubscribe();
      });
  }

  // scenario aspects data querying
  public getScenarioNewsAspectsByIndicator(
    options: ScenarioModule.ScenarioNewsAspectRequestOptions
  ): Observable<ScenarioModule.ScenarioNewsAspectsListResponse> {
    return this.apiService.apollo
      .query({
        query: gql(scenarioNewsAspectQuery),
        variables: {
          sortOrder: options.sortOrder,
          sortProperty: options.sortProperty,
          indicatorId: options.indicatorId,
          ...this.getScenarioFiltersOptionsArguments(options),
          backendRangeStart: convert2UnixTimestamp(options.publishDate[0]),
          backendRangeEnd: convert2UnixTimestamp(options.publishDate[1])
          // TODO this option does not exist on the query, add it when backend allow this option
          // mode: options.mode || this.editMode
        }
      })
      .pipe(
        map((result) => {
          let items: ScenarioModule.ScenarioNewsAspectWithScenarioNews[] = [],
            totalCount = 0,
            totalBackendCount = 0,
            averagePopularity = 0,
            averageScore = 0,
            topNews: ScenarioModule.TopNews[] = [];

          const indicator = result.data && (result as any).data.indicator;
          if (indicator) {
            const data = indicator.data;
            // make items mutable
            items = data.aspects.map(
              (a: ScenarioModule.ScenarioNewsAspectWithScenarioNews) => ({
                ...a,
                state: a.state
              })
            );
            totalCount = data.stats.totalCount;
            averagePopularity = data.stats.averagePopularity;
            averageScore = data.stats.averageScore || 0;
            totalBackendCount = indicator.allBackendEvents.stats.totalCount;
            topNews = data.topNews;
          }

          return {
            aspects: items,
            stats: {
              totalCount,
              averagePopularity,
              averageScore,
              totalBackendCount
            },
            topNews
          };
        })
      );
  }

  public getIndicatorsHeaderInformation(
    indicators: {
      id: number;
      options: ScenarioModule.ScenarioNewsAspectRequestOptions;
    }[]
  ): Observable<{ [key: number]: { stats: ScenarioModule.IndicatorStats } }> {
    const getBaseQuery = (
      id: number,
      options: ScenarioModule.ScenarioNewsAspectRequestOptions
    ) => {
      const mappedOptions = this.getScenarioFiltersOptionsArguments(options);
      const getTemplateLiteralArray = (arr: string[]) => {
        let result = '[';
        result += arr.map((i) => `'${i}'`).join(',');
        result += ']';
        return result;
      };
      // Mark, generated query looks like as follows:
      // i2: indicator(id: 2){
      //    data: scenario_news_and_news_aspects(text: ''.....){
      //      stats{...}
      //    }
      // }
      // Note: since graphQL doesn't allow a single number as an alias. we add letter 'i'(indicator) before.
      // In the end, we have the `i${id}` format as our alias
      // we will slice the letter 'i' in the response mapping so that we only pass necessary information to the component:
      // {
      //   2: {
      //     stats: {...}
      //   },
      //   3: {
      //     stats: {...}
      //   }
      // }
      return `
        i${id}: indicator(id: ${id}){
          id
          data: scenario_news_and_news_aspects(text: '${
  mappedOptions.text
}', title: '${mappedOptions.title}',
            thread_regions: ${getTemplateLiteralArray(
    mappedOptions.thread_regions
  )},
            thread_countries: ${getTemplateLiteralArray(
    mappedOptions.thread_countries
  )},
            sources: ${getTemplateLiteralArray(
    mappedOptions.sources
  )}, source_rank_start: ${mappedOptions.source_rank_start},
            source_rank_end: ${mappedOptions.source_rank_end}, date_start: ${
  mappedOptions.date_start ? mappedOptions.date_start : 0
},
            date_end: ${mappedOptions.date_end ? mappedOptions.date_end : 0}, ){
            stats{
              ...scenarioIndicatorStats
            }
          }
        }
      `;
    };
    let query = '{';
    indicators.forEach((i) => {
      query += getBaseQuery(i.id, i.options);
    });
    query += `
    }
    ${scenarioIndicatorStatsFragment}
    `;

    return this.apiService.apollo
      .query({
        query: gql(query)
      })
      .pipe(
        map((result) => {
          const r = {};
          Object.keys(result.data as any).forEach((indicatorKey) => {
            const key = indicatorKey.slice(1);
            r[key] = (result.data as any)[indicatorKey].data;
          });
          return r;
        })
      );
  }

  public getScenarioNewsExtendedSnippet(
    id: number
  ): Observable<ScenarioModule.ScenarioNewsApsectExtendedSnippet> {
    return this.apiService.apollo
      .query({
        query: gql(scenarioNewsAspectExtendedSnippetQuery),
        variables: {
          id
        }
      })
      .pipe(
        map((result) => {
          return (result.data as any).scenarioNewsAspect
            .extendedSnippet as ScenarioModule.ScenarioNewsApsectExtendedSnippet;
        })
      );
  }
  // TODO: Delete this method after we update all query(query in feed/library/globe module) with new API
  public getNews(options: ScenarioModule.OldScenraioNewsRequestOptions) {
    this.newsQuery = this.apiService.apollo.watchQuery<{
      news: ScenarioModule.OldScenarioNewsListResponse;
    }>({
      query: gql(`query (
        $limit: Int,
        $offset: Int,
        $sortOrder: String,
        $sortProperty: String,
        $title: String,
        $text: String,
        $sources: [String],
        $indicators: [String],
        $rangeStart: Int,
        $rangeEnd: Int,
        $mode: String
      ) {
        pagination {
          scenario_news (
            limit: $limit,
            offset: $offset,
            sort_order: $sortOrder,
            sort_property: $sortProperty,
            title: $title,
            text: $text,
            sources: $sources,
            indicators: $indicators,
            range_start: $rangeStart,
            range_end: $rangeEnd,
            mode: $mode
          ) {
            aspects(mode: $mode, indicators: $indicators) {
              ...scenarioNewsAspect
            }
            ${scenarioNewsBasicFields}
          }
          totalCount: scenario_news_total_count
        }
      }
      ${scenarioNewsAspectFragment}
      `),
      variables: {
        limit: options.limit,
        offset: options.offset,
        sortOrder: options.sortOrder,
        sortProperty: options.sortProperty,
        title: options.title,
        text: options.text,
        rangeStart: options.publishDate.start,
        rangeEnd: options.publishDate.end,
        sources: options.sources,
        indicators: options.indicators,
        mode: options.mode || this.editMode
      }
    });
    const observable = this.newsQuery.valueChanges.pipe(
      map((result) => {
        return {
          items: result.data.pagination.scenario_news,
          total: result.data.pagination.totalCount
        };
      })
    );

    observable.subscribe((data) => {
      this.newsSubject.next(data);

      if (!this.isSubscriptionStarted) {
        this.subscribeToNewNews();
        this.isSubscriptionStarted = true;
      }
    });
  }

  public getAllSources(): Observable<FilterModule.SelectItem[]> {
    return this.apiService.apollo
      .query<{ sources: Gnosis.Source[] }>({
      query: gql(`query allSources{
          sources{
            # source_uuid
            name: source
          }
        }
    `)
    })
      .pipe(
        map((result) => {
          return (result.data.sources || []).map((source) => {
            return { value: source.name, label: source.name };
          });
        })
      );
  }

  public getAllCountries(): Observable<TreeSelectItem[]> {
    return this.apiService.apollo
      .query({
        query: gql(`
        query getAllThreadRegions{
          threadRegions{
            value: name
            label
            children: threadCountries{
              value: name
              label
            }
          }
        }
      `)
      })
      .pipe(
        map((result) => {
          // make regions editable
          return ((result.data as any).threadRegions || []).map((r) => ({
            ...r,
            children: r.children.map((child) => ({ ...child }))
          }));
        })
      );
  }

  public updateNewsAspect(id: number, state: NewsTable.NewsState) {
    return this.apiService.apollo
      .mutate({
        mutation: gql`
          ${updateNewsAspectMutation}
        `,
        variables: {
          id,
          state,
          editionID: this.editionService.currentEditionId
        }
      })
      .pipe(
        map((obs) => {
          return (obs as any).data.updateScenarioNewsAspect;
        })
      );
  }

  public updateIndicatorWeight(id: number, weight: number) {
    return this.apiService.apollo.mutate({
      mutation: gql(updateIndicatorWeightMutation),
      variables: {
        id,
        weight
      }
    });
  }

  public getNewsPreview(id: number): Observable<ScenarioModule.ScenarioNews> {
    return this.apiService.apollo
      .query({
        query: gql(getSingleNewsQuery),
        variables: { id }
      })
      .pipe(
        map((result) => {
          return (result.data as any).scenarioSingleNews;
        })
      );
  }

  private subscribeToNewNews() {
    this.newsQuery.subscribeToMore({
      document: COMMENTS_SUBSCRIPTION,
      updateQuery: (prev: any, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        const newFeedItem = subscriptionData.data.newsAdded;
        newFeedItem.isNew = true;

        setTimeout(() => {
          newFeedItem.isNew = false;
        }, 5000);

        this.currentNews = this.currentNews || prev.news.items;
        this.currentNews = [newFeedItem, ...this.currentNews];

        this.currentTotal = this.currentTotal || prev.news.total;
        this.currentTotal += 1;

        const news = {
          // TODO: should the backend send the total count?
          total: prev.news.total,
          items: this.currentNews
        };

        this.newsSubject.next(news);
      }
    });
  }

  // ultility function

  unsubscribe() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public getProbabilityDirectionImageUrl = (
    dimension: ScenarioModule.AxisDimension,
    score: number
  ) => {
    const basicUrl = '/assets/scenario-module/';

    if (score == null || score === 0) {
      return `url('${basicUrl}none.svg')`;
    }

    const axisDirection = dimension === 'Y' ? 'horizontal' : 'vertical';
    const scoreSignName = this.mapScoreToIndicatorSignPath(score);
    return this.sanitizer.bypassSecurityTrustStyle(
      `url('${basicUrl}${axisDirection}-${scoreSignName}')`
    );
  }

  public getToolTipInformation(key: ToolTipKey) {
    return toolTipInformation[key];
  }

  private mapScoreToIndicatorSignPath(score: number) {
    if (score >= -1 && score < -0.5) {
      return 'full-negative.svg';
    } else if (score >= -0.5 && score < 0) {
      return 'half-negative.svg';
    } else if (score > 0 && score <= 0.5) {
      return 'half-positive.svg';
    } else {
      return 'full-positive.svg';
    }
  }

  private getScenarioFiltersOptionsArguments(
    options:
    | ScenarioModule.ScenarioNewsAspectRequestOptions
    | ScenarioModule.IndicatorsRequestOptions
  ) {
    const args = {
      limit: options.limit,
      offset: options.offset,
      topNewsLimit: options.topNewsLimit,
      ...mapFrontendFiltersArguments2backend(options),
      metaGroupId: options.metaGroupId,
      metaGroupPreviousPeriod: options.metaGroupPreviousPeriod
    };
    return {
      ...args,
      // if present, localPublishDate has higher priority than publishDate
      date_start:
        getArgumentFromDateRange(options.localPublishDate, 0) ||
        args.date_start,
      date_end:
        getArgumentFromDateRange(options.localPublishDate, 1) || args.date_end
    };
  }
}
