<div class="filter-placeholder">
  <span>{{name}}</span>
  <span
    class="filter-placeholder__reset"
    [class.filter-placeholder__reset--hidden]="!isResetButtonShowing"
    (click)="resetFilter()">Clear</span>
</div>
<div class="container">
  <div #infoPanel class="info-placeholder"
    (click)="toggleDropdown()"
    [class.info-placeholder--highlight]="labels.length > 0">
    <div class="info-placeholder__text">{{labelText}}</div>
    <i class="fa info-placeholder__dropdown-icon"
      [class.fa-angle-down]="!isDropdownShowing"
      [class.fa-angle-up]="isDropdownShowing" aria-hidden="true"></i>
  </div>
  <i  class="fa fa-times close-icon"
      [class.close-icon--hidden]="!isCancelIconShowing"
      aria-hidden="true"
      (click)="resetFilter(); closeDropdown()"></i>

  <div #dropdownPanel class="dropdown" [class.dropdown--hidden]="!isDropdownShowing">
    <input
      #filter
      (keyup)="filterFn(filter.value, tree.treeModel)"
      class="dropdown__input" type="text" pInputText placeholder="Search for countries">
    <div class="dropdown__tree-select">
      <tree-root
        #tree
        [(state)]="state"
        [nodes]="nodes"
        [options]="treeOptions"
        (select)="onSelect($event)"
        (deselect)="onSelect($event)"
        >
      </tree-root>
    </div>
  </div>
</div>

<ng-container *ngIf="showChips">
  <div class="chips-container">
    <app-chips-group [chips]="labels" (chipRemove)="onChipRemove($event.chip)"></app-chips-group>
  </div>
</ng-container>