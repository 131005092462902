import { InjectionToken } from '@angular/core';

export const AuthProviderToken = new InjectionToken(
  'angular fire auth provider'
);
export const AuthRedirectUri = new InjectionToken<string>(
  'path to navigate if logged in'
);
export const AutoLoginToken = new InjectionToken<boolean>(
  'enable or disable auto login'
);
