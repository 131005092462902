import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-chips-group',
  templateUrl: './chips-group.component.html',
  styleUrls: ['./chips-group.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipsGroupComponent {
  @Input() chips: FilterModule.SelectItem[] = [];
  @Output() chipRemove = new EventEmitter<{ chip: FilterModule.SelectItem }>();

  removeChip(chip: FilterModule.SelectItem) {
    this.chipRemove.emit({ chip });
  }
}
