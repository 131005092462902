import { map, switchMap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

import { Observable, ReplaySubject, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiService } from '../api.service';
import { TokenService } from '../token.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FilteringService } from '../scenario-stats/filtering.service';
import { AuthService } from '../auth';
import { Router } from '@angular/router';

const viewerPermissions = ['reconsider_news'];
const adminPermissions = [
  ...viewerPermissions,
  'edit_indicator',
  'change_default_filter_set'
];

const roleToPermissions: { [role in UserModule.UserIdentity]: string[] } = {
  DE_U_Role_Gnosis_USCI_Viewer: viewerPermissions,
  DE_U_Role_Gnosis_USCI_Admin: adminPermissions
};

@Injectable()
export class UsersService {
  // current user
  // TODO: update the user type
  user: Observable<UserModule.User>;
  private userSubject: ReplaySubject<UserModule.User>;
  authService: AuthService;

  // all users observable
  allUsers: Observable<UserModule.User[]>;
  private allUsersSubject: ReplaySubject<UserModule.User[]>;

  private loginDomain: string;
  private loginPath: string;

  private authMethod: string;
  private authProvidersCount: number;

  get apollo() {
    return this.apiService.apollo;
  }

  constructor(
    private apiService: ApiService,
    private ngxPermissionService: NgxPermissionsService,
    private tokenService: TokenService,
    private httpClient: HttpClient,
    private filteringService: FilteringService,
    private jwtHelperService: JwtHelperService,

    private injector: Injector,
    private router: Router
  ) {
    this.authMethod = localStorage.getItem('authMethod'); // To get the authentication method chosen by the user
    this.authProvidersCount = Object.keys(environment.auth).length; // to get the number of authentication providers in the config

    /* In case of firebase authentication */
    if (
      (environment.auth.firebaseProvider &&
        !environment.auth.cognitoProvider &&
        !environment.auth.localProvider) ||
      this.authMethod === 'FIREBASE'
    ) {
      this.authService = this.injector.get<AuthService>(AuthService); // inject authservice only when firebase authentication is enabled.
      localStorage.setItem('authMethod', 'FIREBASE');
      this.authService
        .isAuthorized()
        .subscribe((loggedIn) => {
          const urlParams = new URLSearchParams(window.location.search);
          const subscription_id = urlParams.get('subscription_id');
          if (subscription_id) {
            localStorage.setItem('subscription-id', subscription_id);
          }

          !loggedIn ? this.authService.login() : this.redirect();
        });
    }

    /* In case of cognito authentication */
    if (environment.auth.cognitoProvider) {
      this.loginDomain = environment.auth.cognitoProvider
        ? environment.auth.cognitoProvider.loginDomain
        : 'https://login.startgnosis.com';
      this.loginPath = environment.auth.cognitoProvider
        ? environment.auth.cognitoProvider.loginPath
        : '/oauth2/authorize';
    }

    // set user subject
    this.userSubject = new ReplaySubject(1);
    // skip the initial null
    this.user = this.userSubject.asObservable();
    // get user by token when initialization
    if (
      (((environment.auth.localProvider && this.authProvidersCount === 1) ||
        this.authMethod === 'LOCAL') && !environment.production)) {
      const currentGroup: UserModule.UserIdentity[] = [
        'DE_U_Role_Gnosis_USCI_Admin'
      ];
      const devUser: UserModule.User = {
        email: 'haraldGopher@gnosis.technology',
        firstName: 'Harald',
        lastName: 'Gopher',
        username: 'local_backend_developer',
        groups: currentGroup
      };
      this.setUser(devUser);
      // eslint-disable-next-line brace-style
    }
    // in case of firebase authentication
    else if (this.authMethod === 'FIREBASE' || (environment.auth.firebaseProvider && this.authProvidersCount === 1)) {
      const currentGroup: UserModule.UserIdentity[] = [
        'DE_U_Role_Gnosis_USCI_Admin'
      ];
      this.authService.getUser().subscribe((user) => {
        const names = user.displayName.split(',');
        const devUser: UserModule.User = {
          email: user.email,
          firstName: names[1],
          lastName: names[0],
          username: user.displayName,
          groups: currentGroup
        };
        this.setUser(devUser);
      })
    } else {
      // in case of cognito authentication
      if (environment.auth.cognitoProvider) {
        this.loginDomain = environment.auth.cognitoProvider.loginDomain;
        this.getCurrentUser().subscribe((user) => {
          this.setUser(user);
        });
      }
    }

    // all users
    this.allUsersSubject = new ReplaySubject(1);
    this.allUsers = this.allUsersSubject.asObservable();
  }

  public login() {
    const isDev = window.location.pathname.endsWith(
      environment.auth.devLoginRoute || 'devLogin'
    );
    window.open(this.getExternalLoginPage(isDev), '_self');
  }

  public logout() {
    this.tokenService.clearToken();
    this.filteringService.resetAll();
    this.setUser(null);
    // const logoutUri = `${this.loginDomain}/logout?client_id=${environment.auth.clientId}&logout_uri=${this.redirectUri}/logout`;
    // window.open(logoutUri, '_self');
  }

  private getCurrentUser(): Observable<UserModule.User> {
    const accessToken = this.tokenService.getIDToken();

    if (!accessToken) {
      // check whether there is authorization 'code' that can be used to get the token
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get('code');
      if (code) {
        return this.fetchToken(code).pipe(
          switchMap((token: Auth.ServerToken) => {
            this.tokenService.storeToken({
              accessToken: token.access_token,
              idToken: token.id_token,
              refreshToken: token.refresh_token
            });
            // remove all query params;
            window.location.href = `${window.location.origin}${window.location.pathname}`;
            return this.getCurrentUser();
          }),
          catchError(() => {
            console.error(
              'Failed to fetch the token. Requesting a new authorization code...'
            );
            return of(null);
          })
        );
      }
      // auth guard will automatically lead to login
      return of(null);
    }

    // check whether the token is just expired
    if (this.jwtHelperService.isTokenExpired(accessToken)) {
      // use the refresh token to automatically renew the access token
      const { refreshToken } = this.tokenService.getToken();
      return this.refreshToken(refreshToken).pipe(
        switchMap((token: Partial<Auth.ServerToken>) => {
          this.tokenService.storeToken({
            accessToken: token.access_token,
            idToken: token.id_token,
            refreshToken // not contained in the refreshToken
          });
          return this.getCurrentUser();
        }),
        catchError(() => {
          console.warn('Refreshing the token failed. Ignoring the token');
          this.tokenService.clearToken();
          return this.getCurrentUser();
        })
      );
    }

    return this.httpClient
      .get(`${this.loginDomain}/oauth2/userInfo`, {
        headers: {
          Authorization: `Bearer ${this.tokenService.getAccessToken()}`
        }
      })
      .pipe(
        map((response: any) => {
          const { idToken } = this.tokenService.getToken();
          const decodedIdToken = this.jwtHelperService.decodeToken(
            idToken
          ) as Auth.DecodedIdToken;

          const groups = decodedIdToken['cognito:groups'];
          const username = decodedIdToken.given_name;
          const currentUser: UserModule.User = {
            email: response.email,
            firstName: username,
            lastName: decodedIdToken.family_name,
            username,
            groups
          };

          return currentUser;
        }),
        catchError(() => {
          console.error('Could not get the user information');
          return of(null);
        })
      );
  }

  private setUser(user: UserModule.User) {
    if (user) {
      this.setPermissions(user.groups);
    }
    // propagate change to all components
    this.userSubject.next(user);
  }

  private setPermissions(groups: UserModule.UserIdentity[]) {
    this.ngxPermissionService.flushPermissions();
    if (groups == null) { // happens when getting groups via saml. all this has to be re-implemented.
      return;
    }

    const permissions = groups.reduce((allPermissions, group) => {
      return allPermissions.concat(roleToPermissions[group]);
    }, []);
    const uniquePermissions = Array.from(new Set(permissions));
    this.ngxPermissionService.addPermission(uniquePermissions);
  }

  private fetchToken(code: string) {
    const params = {
      grant_type: 'authorization_code',
      client_id: environment.auth.cognitoProvider.clientId,
      code,
      redirect_uri: this.redirectUri
    };
    return this.httpClient.post(`${this.loginDomain}/oauth2/token`, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      params
    });
  }

  private refreshToken(refreshToken: string) {
    const params = {
      grant_type: 'refresh_token',
      client_id: environment.auth.cognitoProvider.clientId,
      refresh_token: refreshToken
    };
    return this.httpClient.post(`${this.loginDomain}/oauth2/token`, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      params
    });
  }

  private getExternalLoginPage(isDev = false) {
    const auth = Object.assign({}, environment.auth);
    if (isDev) {
      auth.localProvider.identityProvider = 'COGNITO';
    }

    const params = [
      `identity_provider=${auth.cognitoProvider.identityProvider || 'DELOITTESAML'
      }`,
      `redirect_uri=${this.redirectUri}`,
      'response_type=code',
      `client_id=${auth.cognitoProvider.clientId}`
    ].join('&');
    return `${this.loginDomain}${this.loginPath}?${params}`;
  }

  private get redirectUri() {
    return (
      environment.auth.cognitoProvider.redirectUri ||
      `${window.location.protocol}//${window.location.host}`
    );
  }

  private redirect() {
    this.router.navigate(['/scenario']); // if looged in already, redirect to scenario page
  }
}
