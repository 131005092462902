import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { ApolloModule } from 'apollo-angular';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { ConfirmationService } from 'primeng/api';
import { ApiService } from '../api.service';
import { ScenarioService } from '../charts/scenario.service';
import { AuthAdminGuard } from '../common/auth.admin.guard';
import { AuthGuard } from '../common/auth.guard';
import { ManageLibraryGuard } from '../common/manage-library.guard';
import { ModalService } from '../modal.service';
import { FilteringService } from '../scenario-stats/filtering.service';
import { SharedModule } from '../shared/shared.module';
import { TokenService } from '../token.service';
import { CommunicationService } from './communication.service';
import { EditModeService } from './edit-mode.service';
import { EditionService } from './edition.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { ScenarioNewsService } from './scenario-news.service';
import { UsersService } from './users.service';
import { MessageService } from 'primeng/api';

export function jwtTokenGetter() {
  const ts = new TokenService();
  return ts.getIDToken();
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule, // provides HttpClient for HttpLink
    ApolloModule,
    HttpLinkModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter
      }
    })
  ],
  providers: [
    ApiService,
    EditModeService,

    UsersService,
    AuthGuard,
    AuthAdminGuard,
    ManageLibraryGuard,

    FilteringService,
    ScenarioNewsService,
    ScenarioService,
    CommunicationService,
    ModalService,
    ConfirmationService,
    MessageService,

    EditionService,
    TokenService
  ],
  declarations: [],
  exports: []
})
export class CoreModule {
  constructor(
  @Optional()
  @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
