import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AuthGuard } from './common/auth.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginComponent } from './login/login';
import { InsufficientPermissionsComponent } from './insufficient-permissions/insufficient-permissions.component';

const authProvidersCount = Object.keys(environment.auth).length; // number of authentication providers in the environment file
const authMethod = localStorage.getItem('authMethod'); // authentication method chosen by user

const lazyModulesRoutes: Routes = [
  // @TODO reactivate the routes in an other version
  // {
  //   path: 'news',
  //   loadChildren: 'app/news/news.module#NewsModule',
  //   canLoad: [AuthGuard],
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'scenario',
    loadChildren: () =>
      import('./scenario-stats/scenario-stats.module').then(
        (m) => m.ScenarioStatsModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'indicators',
    loadChildren: () =>
      import('./indicators-management/indicators-management.module').then(
        (m) => m.IndicatorsManagementModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'alliance-tracker',
    loadChildren: () =>
      import('./alliance-tracker/alliance-tracker.module').then(
        (m) => m.AllianceTrackerModule
      )
  }
];

// alliance_tracker_demo
if (!(environment.features && environment.features.alliances_tracker)) {
  const index = lazyModulesRoutes.findIndex(
    (r) => r.path === 'alliance-tracker'
  );
  if (index > -1) {
    lazyModulesRoutes.splice(index, 1);
  }
}
const appChildrenRoutes: Routes = [
  {
    path: '',
    /* if there are multiple authentication providers or an authentication method is not chosen , navigate to login screen */
    redirectTo: authProvidersCount > 1 && !authMethod ? 'login' : 'scenario',
    pathMatch: 'full'
  },

  ...lazyModulesRoutes,
  { path: '**', component: PageNotFoundComponent }
];

const appRoutes: Routes = [
  {
    path: 'login',
    component:
      authProvidersCount > 1 && !authMethod ? LoginComponent : AppComponent
  },
  {
    path: 'insufficient-permissions',
    component: InsufficientPermissionsComponent
  },
  {
    path: '',
    component: AppComponent,
    children: appChildrenRoutes,
    canActivate: [AuthGuard]
  }
];

if (!environment.auth.disableDevLogin) {
  appRoutes.unshift({
    path: environment.auth.devLoginRoute || 'devLogin',
    redirectTo: 'scenario',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  });
}

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
