import { switchMap, filter, tap } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { RealizationRate, ScenarioService } from './charts/scenario.service';
import { EditModeService } from './core/edit-mode.service';
import { EditionService } from './core/edition.service';
import { UsersService } from './core/users.service';
import { GLOBAL_CONFIG } from './global';
import { ModalService } from './modal.service';
import { FilteringService } from './scenario-stats/filtering.service';
import { MessageService } from 'primeng/api';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  isEditMode: boolean;

  isHeaderCollapsed: boolean;

  toastMsg: Message[] = [];

  currentUser: UserModule.User;

  allEditions: CoreModule.Edition[];
  currentEdition: CoreModule.Edition;

  numberFormat = GLOBAL_CONFIG.numberFormat;
  // scenario module relevant variables
  newScenarioEventsItems: ScenarioModule.NewIndicatorsEvents;
  isScenarioChangesDataLoading = false;
  scenariosRealizationRate: RealizationRate[];
  scenariosRealizationRateComparedRange: ScenarioModule.QuickRangeFilter;

  get scenariosRealizationRateComparedRangeLabel(): string {
    if (!this.scenariosRealizationRateComparedRange) {
      return '';
    }
    if (this.scenariosRealizationRateComparedRange === 'week') {
      return 'over the last 7 days';
    }
    if (this.scenariosRealizationRateComparedRange === 'twoWeeks') {
      return 'over the last 14 days';
    }
    if (this.scenariosRealizationRateComparedRange === 'month') {
      return 'over the last 30 days';
    }
    return 'scince the beginning';
  }

  get showAllianceTrackerModule() {
    return environment.features && environment.features.alliances_tracker === true;
  }

  get showIndicatorModule() {
    return  environment.features && environment.features.dynamic_strategy === true;
  }

  @ViewChild('mainContent', { read: ElementRef }) mainContentRef: ElementRef;

  public isLoading = true;
  public showReloadMessage = false;

  private subscriptions: Subscription[] = [];
  constructor(
    private editModeService: EditModeService,
    private router: Router,
    private modalService: ModalService,
    private usersService: UsersService,
    private cd: ChangeDetectorRef,
    private editionService: EditionService,
    private scenarioService: ScenarioService,
    private filteringService: FilteringService,
    private toastService: MessageService
  ) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.showReloadMessage = true;
    }, 30000);

    this.filteringService.fetchInitialSet();
    const userSubscription = this.usersService.user
      .pipe(
        tap((user) => {
          this.currentUser = user;
        }),
        filter((u) => !!u),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        switchMap((_u) => {
          // check whether whe stored current edition id is valid,
          // if it is not, get it and store it in localStorage and then refresh the page with the right edition id
          this.editionService.getValidEditionId();
          return this.editionService.getAllEditions();
        })
      )
      .subscribe((editions) => {
        this.allEditions = editions;
        this.currentEdition = this.allEditions.find(
          (edition) => edition.id === this.editionService.currentEditionId
        );
        this.cd.markForCheck();
      });

    const globalLoadingStateSubscription = this.modalService.isGlobalLoading$.subscribe(
      (isLoading) => {
        this.isLoading = isLoading;
        this.cd.markForCheck();
      }
    );

    // scenario module information displayed on app level
    const scenarioChangesSubscription = this.scenarioService.scenarioChanges.subscribe(
      (scenarioChanges) => {
        const {
          isLoading,
          newItems,
          realizationRate,
          comparedRange
        } = scenarioChanges;
        this.newScenarioEventsItems = newItems;
        this.isScenarioChangesDataLoading = isLoading;
        this.scenariosRealizationRate = realizationRate || [];
        this.scenariosRealizationRateComparedRange = comparedRange;
        this.cd.markForCheck();
      }
    );

    const toastMsgSubscription = this.toastService.messageObserver.subscribe(
      (msg: Message) => {
        this.toastMsg.push(msg);
      }
    );
    this.subscriptions = [
      userSubscription,
      globalLoadingStateSubscription,
      scenarioChangesSubscription,
      toastMsgSubscription
    ];
  }

  ngAfterViewInit() {
    // show a complete version of scenario-app-header only when user on the top of main content
    this.mainContentRef.nativeElement.addEventListener('scroll', () => {
      this.isHeaderCollapsed =
        this.mainContentRef.nativeElement.scrollTop > 100;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  changeMode(newMode: string) {
    this.isEditMode = newMode === 'edit';
    this.editModeService.setEditMode(this.isEditMode);
  }

  onEditionChange(editionId: number) {
    if (editionId !== this.currentEdition.id) {
      this.editionService.setEdition(editionId);
    }
  }

  logout() {
    this.usersService.logout();
  }

  get isScenarioStatsModule() {
    return this.router.isActive('scenario', false);
  }

  getAbsoluteDifference(
    startProbability: number,
    endProbability: number
  ): number {
    return Math.abs(startProbability - endProbability) * 100;
  }
}
