import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ElementRef,
  forwardRef,
  ChangeDetectorRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-select-items-filter',
  templateUrl: './select-items-filter.component.html',
  styleUrls: ['./select-items-filter.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectItemsFilterComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectItemsFilterComponent implements ControlValueAccessor {
  @Input() set options(allItems: FilterModule.SelectItem[]) {
    this.allItems = allItems;
  }
  @Input() set selectedItems(selectedItems: FilterModule.SelectItem[]) {
    this._selectedItems = selectedItems;
    const _selectedItemsTemp = [];
    this._selectedItems.forEach(item => {
      _selectedItemsTemp.push(this.allItems.find(x => x.value === item.value).label);
    });
    this.selectedItemsValues = _selectedItemsTemp;
    this.updateMultiselectHeaderLabelStyle();
  }
  get selectedItems(): FilterModule.SelectItem[] {
    return this._selectedItems;
  }
  private _selectedItems: FilterModule.SelectItem[] = [];
  public selectedItemsValues: string[] = [];

  allItems: FilterModule.SelectItem[] = [];

  @Input() name: string;
  @Output() filterChange = new EventEmitter<{
    items: FilterModule.SelectItem[];
  }>();

  isDropdownHidden = true;
  get isCancelIconShowing(): boolean {
    return this.selectedItems.length === 1;
  }
  get showResetText(): boolean {
    return this.selectedItems.length > 1;
  }

  private get multiselectHeaderLabel() {
    return this.el.nativeElement.querySelector('.p-multiselect-label');
  }

  constructor(private el: ElementRef, private cd: ChangeDetectorRef) {}

  applyFilter() {
    const _selectedItems = [];
    this.selectedItemsValues.forEach(value => {
      _selectedItems.push(this.allItems.find(x => x.value === value));
    });
    this.selectedItems = _selectedItems;

    // inform parent node and return correct format to parent node
    this.filterChange.emit({
      items: this.selectedItems
    });
    this.propagateChange(this.selectedItems);
  }

  getFilterPlaceholder() {
    return 'Find ' + this.name.toLowerCase();
  }

  resetFilter() {
    this.selectedItems = [];
    this.applyFilter();
  }

  // custom value control
  writeValue(value: any) {
    this.selectedItems = value || [];
    this.cd.markForCheck();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  propagateChange = (_: any) => {};
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched() {}

  private updateMultiselectHeaderLabelStyle() {
    // change the color of label to font-color when something is selected
    // else, change it back to font-color-light
    this.multiselectHeaderLabel.style.color =
      this.selectedItems && this.selectedItems.length ? '#2d3862' : '#97A1C5';
    this.multiselectHeaderLabel.style.opacity =
      this.selectedItems && this.selectedItems.length ? '1' : '0';
  }
}
