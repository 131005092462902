import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopoverModule } from 'iwerk-angular-ui';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { DateFiltersComponent } from './date-filters/date-filters.component';
import { MoreOptionsFiltersComponent } from './more-options-filters/more-options-filters.component';
import { SelectItemsFilterComponent } from './select-items-filter/select-items-filter.component';
import { MessagesInformationComponent } from './messages-information/messages-information.component';
import { PanelModule } from 'primeng/panel';
import { AddonsInputComponent } from './addons-input/addons-input.component';
import { SortHeaderDirective } from './directive/sort-header.directive';
import { SliderModule } from 'primeng/slider';
import { CarouselModule } from 'primeng/carousel';
import { EditorModule } from 'primeng/editor';
import { TabViewModule } from 'primeng/tabview';
import { DebounceClickDirective } from './directive/debounce-click.directive';
import { LexisnexisLogoComponent } from './lexisnexis-logo/lexisnexis-logo.component';
import { TreeModule } from 'angular-tree-component';
import { TreeSelectItemsComponent } from './tree-select-items/tree-select-items.component';
import { ChipsGroupComponent } from './chips-group/chips-group.component';
import { PaginatorModule } from 'primeng/paginator';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RankPipe } from '../scenario-stats/rank.pipe';

@NgModule({
  imports: [
    CommonModule,
    MultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    PopoverModule,
    CalendarModule,
    ButtonModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    OverlayPanelModule,
    CheckboxModule,
    DialogModule,
    TableModule,
    TriStateCheckboxModule,
    AutoCompleteModule,
    SelectButtonModule,
    DataViewModule,
    CardModule,
    RadioButtonModule,
    NgxPermissionsModule,
    TooltipModule,
    PanelModule,
    SliderModule,
    CarouselModule,
    EditorModule,
    TabViewModule,
    TreeModule.forRoot(),
    PaginatorModule,
    ConfirmDialogModule
  ],
  declarations: [
    SelectItemsFilterComponent,
    DateFiltersComponent,
    MoreOptionsFiltersComponent,
    MessagesInformationComponent,
    AddonsInputComponent,
    SortHeaderDirective,
    DebounceClickDirective,
    LexisnexisLogoComponent,
    TreeSelectItemsComponent,
    ChipsGroupComponent,
    RankPipe
  ],
  exports: [
    FormsModule,
    MultiSelectModule,
    PopoverModule,
    CalendarModule,
    ButtonModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    OverlayPanelModule,
    CheckboxModule,
    DialogModule,
    SelectItemsFilterComponent,
    DateFiltersComponent,
    MoreOptionsFiltersComponent,
    SelectButtonModule,
    TableModule,
    TriStateCheckboxModule,
    AutoCompleteModule,
    DataViewModule,
    CardModule,
    RadioButtonModule,
    NgxPermissionsModule,
    TooltipModule,
    MessagesInformationComponent,
    PanelModule,
    AddonsInputComponent,
    SortHeaderDirective,
    SliderModule,
    CarouselModule,
    EditorModule,
    TabViewModule,
    DebounceClickDirective,
    LexisnexisLogoComponent,
    TreeSelectItemsComponent,
    ChipsGroupComponent,
    PaginatorModule,
    ConfirmDialogModule,
    RankPipe
  ],
  providers: [DatePipe, DecimalPipe]
})
export class SharedModule {}
