import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { OverviewComponent } from './overview.component';
import { SignNumberPipe } from './globe/sign.number.pipe';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [CommonModule, CoreModule, SharedModule],
  declarations: [OverviewComponent, SignNumberPipe]
})
export class OverviewModule {}
