import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ViewChild,
  Input,
  forwardRef,
  ChangeDetectorRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-date-filters',
  templateUrl: './date-filters.component.html',
  styleUrls: ['./date-filters.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateFiltersComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateFiltersComponent implements ControlValueAccessor {
  @Input() set dateRange([start, end]: NewsTable.Date) {
    // primeng calendar component does not accept the format [null, null],
    // you need to pass null to indicates that nothing is selected
    if (!start) {
      this.storedDateRange = this.temporaryDateRange = null;
      this.propagateChange(this.storedDateRange);
      return;
    }
    this.storedDateRange = this.temporaryDateRange = [
      new Date(start),
      end ? new Date(end) : null
    ];
  }
  get dateRange() {
    return this.storedDateRange as any;
  }
  @Input() name = 'Time Frame';
  @ViewChild('dateCalendar') datePicker: any;
  @Output() filterChange = new EventEmitter<NewsTable.Date>();
  storedDateRange: [Date, Date] | null;
  temporaryDateRange: [Date, Date] | null;

  get showCancelIcon(): boolean {
    return !!this.storedDateRange;
  }

  constructor(private cd: ChangeDetectorRef) {}

  selectDate() {
    this.applyDate(
      this.temporaryDateRange ? this.temporaryDateRange : undefined
    );
    this.datePicker.overlayVisible = false;
  }

  resetDate() {
    // in order to use default assignment, we need to pass undefined here
    this.applyDate(undefined);
    this.datePicker.overlayVisible = false;
  }

  // custom value control
  writeValue(value: any) {
    this.dateRange = value || [null, null];
    this.cd.markForCheck();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  propagateChange = (_: any) => {};
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched() {}

  private applyDate([fromDate, toDate]: [Date, Date] = [null, null]) {
    const date = [
      fromDate ? fromDate.getTime() : null,
      toDate ? toDate.getTime() : null
    ] as any;
    this.dateRange = date;
    this.filterChange.emit(date);
    this.propagateChange(date);
  }
}
