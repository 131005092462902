import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rank'
})
export class RankPipe implements PipeTransform {
  transform(value: any): any {
    const numberValue = Number(value);
    if (value == null && isNaN(numberValue)) {
      return value;
    }
    return numberValue === 0 ? '—' : numberValue;
  }
}
