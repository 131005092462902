import { Component, Input } from '@angular/core';
import { ModalService } from '../../modal.service';

@Component({
  selector: 'app-messages-information',
  templateUrl: './messages-information.component.html',
  styleUrls: ['./messages-information.component.sass']
})
export class MessagesInformationComponent {
  @Input() messagesCount: number;
  @Input() entityId: number | string;
  @Input() messageEntityType: MessagesModule.EntityType;

  constructor(private modalService: ModalService) {}

  public showAllMessages() {
    this.modalService.setChatVisibility({
      visibility: true,
      entityType: this.messageEntityType,
      entityId: Number(this.entityId)
    });
  }
}
